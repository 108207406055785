<script>
export default {
  computed: {
    // We need this one as long as we have a v2 and v3 frontend
    // By checking for an exception we force every component
    // that checks if it's currently on a vue page (NavBottom, Links)
    // to think it's on an old page
    exceptionThrown() {
      if (typeof this.$el === 'undefined' || this.$el === null) {
        return false;
      }
      return false;
    },
  },
  methods: {
    currentPageIsVuePage() {
      // Met deze setting kunnen we HTML status codes ook in vue een eigen pagina geven zonder de route aan te hoeven passen
      if (this.$store.state.settings.navigation.isVueErrorPage === true) {
        return true;
      }

      // Als het een v2 pagina is, is het sowieso geen Vue page
      const body = document.querySelector('body.v2');
      if (body !== null) {
        return false; // Hij is gevonden, dus geen vue page
      }

      if (this.exceptionThrown) {
        return false;
      }

      return this.pathIsVueRoute(window.location.pathname);
    },
    pathIsVueRoute(path) {
      // TMP disabled, levert onvoorziene issues op op pagina's met _id en stores doordat de navigatie
      // nu _wel_ werkt zoals het hoort
      if (path !== '/') {
        path = path.replace(/\/+$/g, ''); // Weghalen van de laatste slash als hij er is, anders gaat de check fout
      }

      // Checks if the current page is a Vue page, with a <router-view> component
      // We compare each element of the path (split on slash) and ignore the variables (starts with : in the vue route)
      path = this.removeUrlParameters(path);

      // Sommige pagina's zijn v2 maar omdat er in Vue een wildcard
      // route bestaat denkt vue dan dat deze bestaan. Bijvoorbeeld:
      // /reports/_report_id
      // die matched met:
      // /reports/medimomonitor
      // Voor nu kunnnen we die zo snel excluden. Beter is dat deze simpelweg nooit matchen
      const v2Urls = [
        '/reports/medimomonitor',
        '/reports/scores',
        '/reports/scores/scoreOverview',
      ];

      if (v2Urls.includes(path)) {
        return false;
      }

      const actualPathArray = path.split('/');

      // Checks if the current link is a Vue route that's generated by our Folder structure
      for (const route of this.$router.options.routes) {
        // Ignore the catch-all for 404's
        if (route.path.startsWith('/:pathMatch')) {
          continue;
        }

        const checkingPathArray = route.path.split('/');
        // We don't have to check this route if the length of the elements is not the same
        if (actualPathArray.length !== checkingPathArray.length) {
          continue;
        }

        let foundMatch = true;
        for (const pathIndex in checkingPathArray) {
          // Ignore element if it starts with :
          if (checkingPathArray[pathIndex].startsWith(':')) {
            continue;
          }
          // If element is not the same, this is not the same route.
          if (checkingPathArray[pathIndex] !== actualPathArray[pathIndex]) {
            foundMatch = false;
            break;
          }
        }
        // If we are here and the foundMatch is still true, all elements are the same, therefore this must be the route
        if (foundMatch) {
          return true;
        }
      }

      return false;
    },
    removeUrlParameters(path) {
      return path.split('?')[0];
    },
  }
};
</script>
