import {GeneratedMedicationGuardSignal} from '@/domain/models/generated/GeneratedMedicationGuardSignal';

export class MedicationGuardSignal extends GeneratedMedicationGuardSignal {
  public newSignal = false;
  public uniqueHash = '';
  public medicationEvaluationId: number | null = null;
  public drugName = '';
  public created_at = '';
  public title = '';
}
