<template>
  <div>
    <Teleport to="#main" :disabled="!no_overlay" v-if="isMounted">
      <div :class="'medimodal ' + modal_size"
           v-on:keyup.prevent.stop.esc="closeEscape($event)"
           v-on:keyup.prevent.enter="closeEnter($event)"
           :style="{zIndex: z_index, position: position ? position : (no_overlay ? 'relative' : false)}"
           @click="onClicked"
           @mousedown="onMouseDown"
      >
        <div class="modal" v-bind:class="{'hide-overlay': hide_overlay, 'no-overlay': no_overlay}" tabindex="-1" role="dialog" :id="uid">
          <div role="document"
               v-draggable
               :class="'modal-dialog modal-dialog-scrollable '  + modal_size + ' ' + (isTopModal ? 'top-modal' : '')">
            <div class="modal-content" v-click-outside="closeOutsideClick" :style="modal_content_style">
              <medimo-loader-overlay :autoHideLoader="autoHideLoader" transition="no-fade" size="32" v-if="loading" :loading="true" />
              <div class="modal-header unselectable">
                <slot name="modal-header">
                  <div class="modal-title my-auto col-auto">
                    <span class="modal-title">{{ title }}</span>
                    <slot name="title-buttons"></slot>
                  </div>
                </slot>
                <div class="close ml-auto my-auto col-auto mr-0 pr-0" data-dismiss="modal" aria-label="Close" @click.stop="close">
                  <!-- Only show the Sluiten text when we're not on a phone -->
                  <span v-if="size !== 'small'" class="d-none d-md-inline" id="header-close-button">Sluiten</span>
                  <fa-icon icon="fa-solid fa-close" size="lg" />
                </div>
              </div>
              <div :class="'modal-body ' + uid" ref="modal-body" @scroll="onContentScroll" tabindex="0">
                <slot></slot>
              </div>
              <div class="modal-footer">
                <transition name="fade">
                  <!-- This little div indicates the modal is scrollable with a small gradient -->
                  <div class="scroll-overlay" :style="{zIndex: z_index}" v-show="hasScrollableContent && !isAtBottomOfContent"></div>
                </transition>
                <slot name="footer-content"></slot>
                <slot name="action-buttons">
                  <!-- <button type="button" class="btn btn-primary">Save changes</button>-->
                  <medimo-button type="button" class="btn btn-medimo" @click.stop="close">
                    {{ closeButtonString }}
                  </medimo-button>
                </slot>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Teleport>
  </div>
</template>

<script>

import MedimoButton from './MedimoButton';
import MedimoLoaderOverlay from './MedimoLoaderOverlay';

// Deze moeten hier ook komen omdat de MediModal ook in v2 wordt ingeladen en dan soms een directive error lijkt te geven
import draggable from '@/vue/directives/Draggable';
import ClickOutside from '@/vue/directives/ClickOutside';
import {nextTick} from 'vue';

export default {
  components: {
    MedimoButton,
    MedimoLoaderOverlay,
  },

  emits: ['closed','opened'],

  directives: {
    draggable,
    ClickOutside,
  },

  props: {
    // If you change this value, you toggle the visibility, ensuring things are in sync
    // It does double duty as the initial visibility
    'trigger': {default: false},
    'loading': {default: false},
    'initial_z_index': {default: 2019},
    'title': {default: ''},
    'size': {default: ''},
    'min_height': {default: 0},

    // Hides the background. Since this stacks, it will cause a total blackout if there are multiple overlapping
    'hide_overlay': {default: false},
    // Removes the background, click it to close is also not possible anymore. This gives the possibility to open multiple popups next to each other
    'no_overlay': {default: false},
    'autoHideLoader': {default: 30000},

    'closeButtonString': {default: 'Sluiten'},
    'position': {default: ''},
    'closeOnEnter': {default: false},
  },

  data: function () {
    return {
      clientHeight: 0,
      scrollHeight: 0,
      scrollPosition: 0,

      // We update this value by 1 each time the user scrolls.
      // This allows us to watch this value from a child component, and update / do things @ the Child level
      // when the parent is scrolling.
      // Use the WorksWithParentsAndSiblings mixin to get a parent in the child, then take it from there.
      scrollCounter: 0,
      z_index: this.initial_z_index,
      isMounted: false,
      canCloseOnEnter: false,
    };
  },

  computed: {
    mediModalUid() {
      return this._.uid;
    },
    modal_size() {
      if (this.size === 'fullscreen') {
        return 'modal-fullscreen';
      }
      if (this.size === 'extra-large') {
        return 'modal-xl';
      }
      if (this.size === 'large') {
        return 'modal-lg';
      }
      if (this.size === 'small') {
        return 'modal-sm';
      }
      return 'modal-md';
    },
    modal_content_style() {
      // Additional styles
      if (this.min_height > 0) {
        return 'min-height: ' + this.min_height + ';';
      }
    },
    uid() {
      return 'modal' + this._.uid;
    },
    hasScrollableContent() {
      nextTick(() => {
        return this.scrollHeight > this.clientHeight;
      });
    },
    isAtBottomOfContent() {
      return this.scrollPosition === this.scrollHeight;
    },
    openMediModalUids() {
      return this.$store.state.settings.modals.openMediModalUids;
    },
    isTopModal() {
      // Checks the last UID in the array and if it's a match, we know it's the top Modal
      // Safer than doing a viewport check
      // When a modal does not use the overlay, it's always a top modal (and multiple can be)
      return this.openMediModalUids[this.openMediModalUids.length - 1].uid === this._.uid || this.no_overlay;
    },
    allowedToClose() {
      return this.$store.getters['settings/modals/allowed_to_close'];
    },
  },

  created() {
    this.addToOpenMedimodalUids();
  },

  updated() {
    this.updateScrollData();
  },

  beforeUnmount() {
    this.removeFromOpenMedimodalUids();
  },

  mounted() {
    nextTick(() => {
      this.isMounted = true;
      this.enableEscapeClose();

      setTimeout(() => {
        this.canCloseOnEnter = true;
      }, 50); // Kleine delay zodat hij niet bij een @enter van een row wdirect weer sluit
    });
  },

  methods: {
    onClicked() {
      this.updateScrollData();
      setTimeout(() => {
        // And once more, or else we get into trouble with the MedimoDateTimePicker closing
        // and the overlay still being there
        this.updateScrollData();
      }, 30);
    },
    onMouseDown() {
      if (this.no_overlay) {
        const max = this.$store.getters['settings/modals/highest_z_index'];
        if (max >= this.z_index) {
          this.z_index = max + 1;
          this.$store.commit('settings/modals/update_open_medimodal_uids', {uid: this._.uid, zIndex: this.z_index});
        }
      }
    },
    onContentScroll() {
      this.scrollCounter++;
      // Update the scroll position when the body is being scrolled
      this.scrollPosition = this.$refs['modal-body'].scrollTop + this.$refs['modal-body'].clientHeight;

      this.updateScrollData();
    },
    close(event) {
      if (this.isTopModal && this.allowedToClose) {
        this.$emit('closed', event);
      }
    },
    open() {
      this.$emit('opened');
    },
    closeEnter(event) {
      if (this.closeOnEnter && this.canCloseOnEnter) {
        this.close(event);
      }
    },
    closeEscape(event) {
      this.close(event);
    },
    closeOutsideClick() {
      if (!this.no_overlay) {
        this.close(event);
      }
    },
    removeFromOpenMedimodalUids() {
      this.$store.commit('settings/modals/remove_from_open_medimodal_uids', this._.uid);
    },
    addToOpenMedimodalUids() {
      this.$store.commit('settings/modals/add_to_open_medimodal_uids', {uid: this._.uid, zIndex: this.z_index});
    },
    updateScrollData() {
      const vm = this;
      // Set the scrollHeight and clientHeight on an update
      // Since this is based on the DOM content, we can't use watchers.
      nextTick(() => {
        setTimeout(() => {
          if (vm.$refs['modal-body'] !== null) {
            vm.clientHeight = vm.$refs['modal-body'].clientHeight;
            vm.scrollHeight = vm.$refs['modal-body'].scrollHeight;
          }
        }, 5);
      });
    },
    enableEscapeClose() {
      // Focus the modal body after opening so ESC is able to close the modal
      nextTick(() => {
        this.$refs['modal-body']?.focus();
      });
    }
  },

  watch: {
    loading() {
      this.updateScrollData();
    },
  },
};
</script>
<style scoped lang="scss">
.medimodal .modal-body:focus {
  outline: 0;
}

#header-close-button {
  top: -1px;
  position: relative;
}

.modal-title {
  [class^=icon] {
    font-size: 15px;
    top: 1px;
    position: relative;
  }
}
</style>
