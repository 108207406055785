<template>
    <div>
      <status-overview-medication-table
          :loading="false"
          :patient="patient"
          :dateTime="dateTime"
      >
      </status-overview-medication-table>

      <medimo-table :read_only="true" tabindex="1" :enable_filter="false"
                    :no_results_message="indications_message"
                    :number_of_rows="indications === null ? 0 : indications.length"
                    :autofocus="false"
                    :can_be_focused="false"
      >
        <medimo-thead>
          <medimo-tr class="no-gutters" :keynav="false">
            <medimo-th column_classes="col-sm-3">
              Contra-indicaties
            </medimo-th>
            <medimo-th column_classes="col-sm-5">
              Bijzonderheden
            </medimo-th>
            <medimo-th column_classes="col-sm-4">
              Vaststellingsmoment
            </medimo-th>
          </medimo-tr>
        </medimo-thead>
        <medimo-tbody :transition_group="true" transition_name="fade-fast">
          <indication-row @selected_row="set_selected_id"
                          v-for="(indication, index) in indications"
                          :indication="indication"
                          :index="index" :key="indication.id">
          </indication-row>
        </medimo-tbody>
      </medimo-table>

      <medimo-table :read_only="true" tabindex="1" :enable_filter="false"
                    :no_results_message="intolerances_message"
                    :number_of_rows="intolerances === null ? 0 : intolerances.length"
                    :autofocus="false"
                    :can_be_focused="false"
      >
        <medimo-thead>
          <medimo-tr class="no-gutters" :keynav="false">
            <medimo-th column_classes="col-sm-3">
              Allergieën / Intoleranties
            </medimo-th>
            <medimo-th column_classes="col-sm-5">
              Bijzonderheden
            </medimo-th>
            <medimo-th column_classes="col-sm-4">
              Vaststellingsmoment
            </medimo-th>
          </medimo-tr>
        </medimo-thead>
        <medimo-tbody :transition_group="true" transition_name="fade-fast">
          <intolerance-row @selected_row="set_selected_id"
                           v-for="(intolerance, index) in intolerances"
                           :intolerance="intolerance"
                           :index="index" :key="intolerance.id">
          </intolerance-row>
        </medimo-tbody>
      </medimo-table>
    </div>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import StatusOverviewMedicationTable from './StatusOverviewMedicationTable';
import IndicationRow from './IndicationRow';
import IntoleranceRow from './IntoleranceRow';

export default {
  emits: ['change_loading'],
  components: {IntoleranceRow, IndicationRow, StatusOverviewMedicationTable},

  props: {
    'patient': {required: true},
    'fetch_patient_on_create': {default: true},
    'dateTime': {default: null},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      height: 0,
      selected_id: 1,
      loading: false,
    };
  },

  computed: {
    intolerances() {
      return this.$store.getters['intolerances/all'];
    },
    indications() {
      return this.$store.getters['indications/all'];
    },
    indications_message() {
      return this.indications === null ? 'Onbekend of er contra-indicaties zijn' : 'Geen contra-indicaties aanwezig';
    },
    intolerances_message() {
      return this.intolerances === null ? 'Onbekend of er allergieën/intoleranties zijn' : 'Geen allergieën/intoleranties aanwezig';
    },
  },

  created() {
    if (this.fetch_patient_on_create) {
      this.loading = true;
      // Refresh data on creating modal...
      this.$store.dispatch('patients/fetch_single', {id: this.patient.id}).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    }
  },

  mounted() {
    //
  },

  methods: {
    set_selected_id(row_id) {
      this.selected_id = row_id;
    },
  },

  watch: {
    loading() {
      this.$emit('change_loading', this.loading);
    }
  }
};
</script>
