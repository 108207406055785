<template>
  <MediModal
      :loading="loading"
      :title="title"
      size="medium"
      @closed="close()"
  >
    <MedimoForm>

      <MedimoFormRow v-if="props.action.status !== null" id="assigned-to" class="mb-2">
        <MedimoFormColumn column_classes="col-12 col-sm-3">
          <label>Voorschrift</label>
        </MedimoFormColumn>
        <MedimoFormColumn column_classes="col-12 col-sm-9">
          <label>{{ props.action.status }}</label>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow class="mb-2">
        <MedimoFormColumn column_classes="col-12 col-sm-3">
          <label>Verantwoordelijk</label>
        </MedimoFormColumn>

        <MedimoFormColumn column_classes="col-11 col-sm-9">
          <MedimoFilterButton
              @toggle="processToggleAssigned('Physician')"
              :active="isAssignedToPhysician"
              extra_classes="mr-2">
            Arts
          </MedimoFilterButton>
          <MedimoFilterButton
              @toggle="processToggleAssigned('Care')"
              :active="isAssignedToCare"
              extra_classes="mr-2">
            Zorg
          </MedimoFilterButton>
          <MedimoFilterButton
              @toggle="processToggleAssigned('Pharmacy')"
              :active="isAssignedToPharmacy"
              extra_classes="mr-2">
            Apotheker
          </MedimoFilterButton>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow id="visible-for" class="mb-2">
        <MedimoFormColumn column_classes="col-12 col-sm-3">
          <label>Zichtbaar voor</label>
        </MedimoFormColumn>

        <MedimoFormColumn column_classes="col-12 col-sm-9">
          <MedimoFilterButton
              @toggle="processToggleParticipant('Physician')"
              :active="isVisibleForPhysician"
              :extra_classes="(isAssignedToPhysician ? 'disabled ' : '') + 'mr-2'"
              :help_text="participantHelpText(isAssignedToPhysician)">
            Arts
          </MedimoFilterButton>

          <MedimoFilterButton
              @toggle="processToggleParticipant('Care')"
              :active="isVisibleForCare"
              :extra_classes="(isAssignedToCare ? 'disabled ' : '') + 'mr-2'"
              :help_text="participantHelpText(isAssignedToCare)">
            Zorg
          </MedimoFilterButton>

          <MedimoFilterButton
              @toggle="processToggleParticipant('Pharmacy')"
              :active="isVisibleForPharmacy"
              :extra_classes="(isAssignedToPharmacy ? 'disabled ' : '') + 'mr-2'"
              :help_text="participantHelpText(isAssignedToPharmacy)">
            Apotheker
          </MedimoFilterButton>

        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow class="mb-2">
        <MedimoFormColumn column_classes="col-12 col-sm-3">
          <label>Opties</label>
        </MedimoFormColumn>

        <MedimoFormColumn column_classes="col-12 col-sm-9">
          <MedimoFilterButton
              @toggle="processTogglePrivacy"
              :active="isPrivate"
              extra_classes="isPrivate mr-2"
              help_text="Markeer als privé actie">
            Prive
          </MedimoFilterButton>

          <MedimoFilterButton
              @toggle="informThem = !informThem"
              :active="informThem"
              extra_classes="informThem mr-2"
              help_text="Informeer betrokkenen">
            Informeer
          </MedimoFilterButton>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow v-if="description" class="mb-2">
        <MedimoFormColumn column_classes="col-12 col-sm-3">
          <label>Omschrijving</label>
        </MedimoFormColumn>
        <MedimoFormColumn column_classes="col-12 col-sm-9">
          <MedimoFormattedText :text="description" type="div" class="mt-2 mb-2" />
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow v-if="chatMessages.length && !props.action.isActive" class="mb-2">
        <MedimoFormColumn column_classes="col-12">
          <label>{{ berichtgevingLabel }}</label>
        </MedimoFormColumn>
        <MedimoFormColumn column_classes="col-12"
                          v-for="(message, index) in chatMessages"
                            :index="index"
                            :key="message.id">
          <chat-message :message="message"></chat-message>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow v-if="explanationVisible" class="mb-2">
        <MedimoFormColumn column_classes="col-12">
          <medimo-labeled-textarea
              label_column_classes="col-12 col-sm-3"
              label="Toelichting"
              placeholder="Uw toelichting"
              name="explanation"
              v-model="explanation"
              rows="3"
          ></medimo-labeled-textarea>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow v-if="nextValidUntilDateVisible" class="mb-2">
        <MedimoFormColumn column_classes="col-12">
          <MedimoLabeledDatePicker
              label_column_classes="col-12 col-sm-3"
              :label="nextValidUntilLabel"
              datetime_format="DD-MM-YYYY HH:mm"
              :minDate="props.action.validUntil"
              name="next_valid_until_date"
              v-model="nextValidUntilDate"
          ></MedimoLabeledDatePicker>
        </MedimoFormColumn>
      </MedimoFormRow>

      <MedimoFormRow v-if="activeView === 'delete'" class="mb-2">
        <MedimoFormColumn column_classes="col-12">
          Weet u zeker dat u deze actie voor altijd wilt onderdrukken?
        </MedimoFormColumn>
      </MedimoFormRow>

      <div v-if="activeView === 'history'">
        <MedimoFormRow class="mb-2">
          <MedimoFormColumn column_classes="col-12">
            <label>{{ berichtgevingLabel }}</label>
          </MedimoFormColumn>
        </MedimoFormRow>
        <MedimoFormRow class="mb-2">
          <MedimoFormColumn column_classes="col-12"
                            v-for="(message, index) in chatMessages"
                              :index="index"
                              :key="message.id">
            <ChatMessage :message="message"></ChatMessage>
          </MedimoFormColumn>
        </MedimoFormRow>
        <MedimoFormRow>
          <MedimoFormColumn column_classes="col-12">
            <MedimoLabeledTextarea
                label_column_classes="col-12 col-sm-3"
                label="Bericht toevoegen"
                placeholder="Uw bericht"
                name="message"
                v-model="message"
                rows="2"
            ></MedimoLabeledTextarea>
          </MedimoFormColumn>
        </MedimoFormRow>
      </div>

    </MedimoForm>

    <template v-slot:action-buttons>

      <div v-if="activeView === 'done'">
        <MedimoButton class="mr-2" @click="viewDelete" type="outline-primary">
          Verwijder
        </MedimoButton>
        <MedimoButton class="mr-2" @click="viewDelay" type="outline-primary">
          Uitstellen
        </MedimoButton>
        <MedimoButton class="mr-2" @click.stop="viewHistory" type="outline-primary">
          Historie
        </MedimoButton>
        <MedimoButton class="mr-2" @click="processForm" :disabled="saveButtonDisabled">
          Afhandelen
        </MedimoButton>
      </div>

      <div v-else-if="activeView === 'reactivate'">
        <MedimoButton class="mr-2" @click="close">
          Sluiten
        </MedimoButton>
        <MedimoButton class="mr-2" @click="processForm" :disabled="saveButtonDisabled">
          Heractiveer
        </MedimoButton>
      </div>

      <div v-else-if="activeView === 'history'">
        <MedimoButton class="mr-2" @click="viewDone">
          Sluiten
        </MedimoButton>
        <MedimoButton class="mr-2" @click="processForm" :disabled="saveButtonDisabled">
          Voeg bericht toe
        </MedimoButton>
      </div>

      <template v-else >
        <MedimoNotification type="danger" class="mr-auto col-md-6">
          <fa-icon icon="fa-solid fa-bell" class="bell" size="lg" /> Weet je het zeker?
        </MedimoNotification>

        <MedimoButton class="mr-2" @click="viewDone" type="outline-primary">
          Annuleren
        </MedimoButton>
        <MedimoButton class="mr-2" @click.prevent="processForm" :disabled="saveButtonDisabled">
          Ja, ik weet het zeker
        </MedimoButton>
      </template>

    </template>
  </MediModal>
</template>

<script setup lang="ts">
import MediModal from "@/vue/components/general/MediModal.vue";
import MedimoForm from "@/vue/components/general/form/MedimoForm.vue";
import MedimoFormRow from "@/vue/components/general/form/base/MedimoFormRow.vue";
import MedimoFormColumn from "@/vue/components/general/form/base/MedimoFormColumn.vue";
import MedimoLabeledTextarea from "@/vue/components/general/form/base/MedimoLabeledTextarea.vue";
import MedimoButton from "@/vue/components/general/MedimoButton.vue";
import ChatMessage from "@/vue/components/patients/chats/ChatMessage.vue";
import MedimoNotification from "@/vue/components/general/MedimoNotification.vue";
import MedimoFilterButton from '@/vue/components/general/form/base/MedimoFilterButton.vue';
import MedimoLabeledDatePicker from "@/vue/components/general/form/base/MedimoLabeledDatePicker.vue";
import {Action} from "@/domain/models/Action";
import {Patient} from "@/domain/models/Patient";
import {computed, onMounted, ref, watch} from "vue";
import {useStore} from "vuex";
import moment from "moment-mini";
import MedimoFormattedText from "@/vue/components/general/MedimoFormattedText.vue";

const emit = defineEmits(['closed', 'processed']);

interface Props {
  action: Action,
  patient: Patient,
}

const props = defineProps<Props>();

const store = useStore();
const loading = ref<boolean>(false);

const isAssignedToCare = ref<boolean>(false);
const isAssignedToPharmacy = ref<boolean>(false);
const isAssignedToPhysician = ref<boolean>(false);

const isVisibleForCare = ref<boolean>(false);
const isVisibleForPharmacy = ref<boolean>(false);
const isVisibleForPhysician = ref<boolean>(false);

const activeView = ref<string>('done');
const title = ref<string>('');
const description = ref<string>('');
const explanation = ref<string>('');
const message = ref<string>('');

const isRecurrent = ref<boolean>(false);
const isPrivate = ref<boolean>(false);
const informThem = ref<boolean>(false);

const nextValidUntilDate = ref<string>('');
const nextValidUntilLabel = ref<string>('Stel uit tot');

const berichtgevingLabel = ref<string>('Berichtgeving over deze actie:');
const buttonSaveText = ref<string>('Opslaan');

onMounted(() => {
  // Toon de chat messages altijd als hij inactief is
  if (!props.action.isActive) {
    fetchChatMessages();
  }
  loadActionValues();
});

const chatMessages = computed(() => {
  const messages = store.getters['actions/state']('chat_messages')[props.action.id];
  if (typeof messages === 'undefined') {
    return [];
  }
  return messages;
});

const nextValidUntilDateVisible = computed<boolean>(() => {
  return activeView.value === 'delay'
      || activeView.value === 'reactivate'
      || (activeView.value === 'done' && isRecurrent.value);
});

const explanationVisible = computed<boolean>(() => {
  return activeView.value === 'done' || activeView.value === 'delay' || activeView.value === 'reactivate';
});

const saveButtonDisabled = computed<boolean>(() => {
  // toelichting vereist
  if (explanationVisible.value && !explanation.value.length) {
    return true;
  }
  // message empty
  if (activeView.value === 'history' && !message.value.length) {
    return true;
  }

  // date in past, or less then validUntil
  if (nextValidUntilDateVisible.value) {
    const nextValid = moment(nextValidUntilDate.value);
    if (nextValid.isBefore()) {
      return true;
    }

    if (props.action.validUntil && nextValid.isBefore(moment(props.action.validUntil))) {
      return true;
    }
  }

  return false;
});

function participantHelpText(visible: boolean): string {
  if (visible) {
    return 'Actie is altijd zichtbaar voor de verantwoordelijke';
  }

  return '';
}

function close(): void {
  emit('processed');
  emit('closed');
}

function viewDelete(): void {
  activeView.value = 'delete';
  buttonSaveText.value = 'Verwijder!';
}

function viewDelay(): void {
  activeView.value = 'delay';
  buttonSaveText.value = 'Stel uit';
  nextValidUntilLabel.value = 'Stel uit tot';
}

function viewHistory(): void {
  activeView.value = 'history';
  buttonSaveText.value = 'Voeg bericht toe';
  fetchChatMessages();
}

function fetchChatMessages(): void {
  loading.value = true;
  store.dispatch('actions/fetchChatMessages', {
    patient_id: props.patient.id,
    action_id: props.action.id
  }).then(() => {
    loading.value = false;
  }).catch(() => {
    loading.value = false;
  });
}

function addChatMessage(): void {
  loading.value = true;

  store.dispatch('actions/addChatMessage', {
    patient_id: props.patient.id,
    action_id: props.action.id,
    data: {
      informThem: informThem.value,
      message: message.value
    }
  }).then(() => {
    loading.value = false;
    message.value = '';
  }).catch(() => {
    loading.value = false;
  });
}

function viewDone(): void {
  activeView.value = 'done';
}

function viewReactivate(): void {
  activeView.value = 'reactivate';
  nextValidUntilLabel.value = 'Af te handelen voor';
}

function processForm(): void {
  switch (activeView.value) {
    case "done":
      processDone();
      break;
    case "delete":
      processDelete();
      break;
    case "delay":
      processDelay();
      break;
    case "history":
      addChatMessage();
      break;
    case "reactivate":
      processReactivate();
      break;
  }
}

function processToggleAssigned(entityType: string) {
  doProcess('toggleAssigned', {
    entityType: entityType
  }, () => updateAssigned(entityType));
}

function updateAssigned(entityType: string): void {
  switch (entityType) {
    case 'Care':
      isAssignedToCare.value = !isAssignedToCare.value;
      break;
    case 'Pharmacy':
      isAssignedToPharmacy.value = !isAssignedToPharmacy.value;
      break;
    case 'Physician':
      isAssignedToPhysician.value = !isAssignedToPhysician.value;
      break;
  }
}

function processToggleParticipant(entityType: string): void {
  doProcess('toggleParticipant', {
    entityType: entityType
  }, () => updateParticipant(entityType));
}

function updateParticipant(entityType: string): void {
  switch (entityType) {
    case 'Care':
      isVisibleForCare.value = !isVisibleForCare.value;
      break;
    case 'Pharmacy':
      isVisibleForPharmacy.value = !isVisibleForPharmacy.value;
      break;
    case 'Physician':
      isVisibleForPhysician.value = !isVisibleForPhysician.value;
      break;
  }
}

function processTogglePrivacy(): void {
  doProcess('togglePrivacy', {}, () => isPrivate.value = !isPrivate.value);
}

function processDone(): void {
  const data: { [key: string]: boolean | string } = {
    explanation: explanation.value,
    informThem: informThem.value,
  };
  if (nextValidUntilDateVisible.value) {
    data.nextValidUntilDate = nextValidUntilDate.value;
  }
  doProcess('done', data, close);
}

function processDelete(): void {
  doProcess('delete', {
    informThem: informThem.value,
  }, close);
}

function processDelay(): void {
  doProcess('delay', {
    informThem: informThem.value,
    explanation: explanation.value,
    nextValidUntilDate: nextValidUntilDate.value
  }, close);
}

function processReactivate(): void {
  doProcess('reactivate', {
    informThem: informThem.value,
    explanation: explanation.value,
    nextValidUntilDate: nextValidUntilDate.value
  }, close);
}

function doProcess(handler: string, data: object, callback?: () => void): void {
  loading.value = true;

  store.dispatch('actions/update', {
    patient_id: props.patient.id,
    handler: handler,
    action_id: props.action.id,
    data: data
  }).then(() => {
    loading.value = false;
    if (typeof callback === 'function') {
      callback();
    }
  }).catch(() => {
    loading.value = false;
  });
}

function loadActionValues(): void {
  if (props.action === null) {
    return;
  }

  title.value = props.action.name;
  description.value = props.action.description;

  isAssignedToCare.value = props.action.isAssignedToCare;
  isVisibleForCare.value = props.action.isParticipatingCare;

  isAssignedToPharmacy.value = props.action.isAssignedToPharmacy;
  isVisibleForPharmacy.value = props.action.isParticipatingPharmacy;

  isAssignedToPhysician.value = props.action.isAssignedToPhysician;
  isVisibleForPhysician.value = props.action.isParticipatingPhysician;

  isRecurrent.value = props.action.isRecurrent;
  isPrivate.value = props.action.privacy;

  const addDays = props.action.repeatAfterDays ? props.action.repeatAfterDays : 7;
  nextValidUntilDate.value = moment(moment(), "DD-MM-YYYY").add(addDays, 'days').format('YYYY-MM-DD HH:mm');

  explanation.value = '';

  // when action not active, load reactivate view
  if (!props.action.isActive) {
    viewReactivate();
  } else {
    viewDone();
  }
}

watch([isAssignedToCare, isAssignedToPharmacy, isAssignedToPhysician], (
  [newIsAssignedToCare, newIsAssignedToPharmacy, newIsAssignedToPhysician],
  [prevIsAssignedToCare, prevIsAssignedToPharmacy, prevIsAssignedToPhysician]
) => {
  if (newIsAssignedToCare && newIsAssignedToCare != prevIsAssignedToCare) {
    isVisibleForCare.value = true;
  }

  if (newIsAssignedToPharmacy && newIsAssignedToPharmacy != prevIsAssignedToPharmacy) {
    isVisibleForPharmacy.value = true;
  }

  if (newIsAssignedToPhysician && newIsAssignedToPhysician != prevIsAssignedToPhysician) {
    isVisibleForPhysician.value = true;
  }
});
</script>
