<template>
    <div class="page-component mx-auto" v-bind:class="{
      'transparent': transparent,
      'm-height': minimum_height,
      'no-border-radius-top': no_border_radius_top,
      'no-border-radius-bottom': no_border_radius_bottom,
    }"
     v-bind:style="{
      'min-width': page_block_size + 'px',
    }">
      <medimo-loader-overlay :show-background="!transparent || showLoaderBackground" :loading="loading"></medimo-loader-overlay>
      <template v-if="title.length">
        <div class="row">
          <div class="col d-flex">
            <component :is="title_class" class="my-auto">
              <i :class="icon_class" v-if="icon_class.length"></i>
              <fa-icon :icon="icon_fa" v-else-if="icon_fa.length" size="lg" />
              <component class="d-inline" :is="title_class">{{ title }}</component>
              <span v-if="subtitle.length" class="h1 bright-blue">|</span>
              <small v-if="subtitle.length">{{ subtitle }}</small>
            </component>
            <slot name="action_buttons_alternative"></slot>
          </div>
          <!-- De col-auto zorgt er voor dat hij altijd netjes links uitlijnt -->
          <div class="col-auto" v-if="$slots.action_buttons">
            <slot name="action_buttons"></slot>
          </div>
        </div>
        <slot name="description"></slot>
        <hr v-if="hr" />
      </template>
        <slot></slot>
    </div>
</template>

<script>
import MedimoLoaderOverlay from '../MedimoLoaderOverlay';

export default {
  components: {MedimoLoaderOverlay},

  props: {
    showLoaderBackground: {default: false},
    transparent: {default: false},
    title: {type: [String, null], default: ''},
    title_class: {default: 'h1'},
    subtitle: {type: [String, null], default: ''},
    icon: {default: ''},
    loading: {default: false},

    // Om de horizontale regel weg te laten, kans soms onwenselijk zijn
    hr: {default: true},

    minimum_height: {default: false},
    size: {default: ''},
    no_border_radius_top: {default: false}, // Set to true to enable the top corner radius
    no_border_radius_bottom: {default: false}, // Set to true to enable the bottom corner radius
  },

  mixins: [],

  data: function () {
    return {
      originalContentContainerMaxWidth: 0,
    };
  },

  computed: {
    icon_class() {
      return (!this.icon.length || this.icon.startsWith('fa-')) ? '' : this.icon;
    },
    icon_fa() {
      return this.icon.startsWith('fa-') ? this.icon : '';
    },
    hiddenClass() {
      if (this.size === 'extra-extra-large') {
        return 'hidden-xxl-down';
      }
      if (this.size === 'extra-large') {
        return 'hidden-xl-down';
      }

      return 'hidden-lg-down'; // default
    },
    page_block_size() {
      if (this.size === 'extra-extra-large') {
        return 1675;
      }
      if (this.size === 'extra-large') {
        return 1475;
      }
      if (this.size === 'large') {
        return 1250;
      }
      if (this.size === 'medium') {
        return 769;
      }
      return 0; // Default, ignore
    },
  },

  created() {
    this.originalContentContainerMaxWidth = parseInt(getComputedStyle(document.querySelector("#content-container")).maxWidth.replace('px', ''));

    this.setContentContainerWidth();
  },

  unmounted() {
    if (this.page_block_size > this.originalContentContainerMaxWidth) {
      this.changeContainerPageBlockWidth(this.originalContentContainerMaxWidth, 'hidden-lg-down');
    }
  },

  methods: {
    changeContainerPageBlockWidth(maxWidth, hiddenClass) {
      const contentElement = document.querySelector("#content-container");
      contentElement.style["max-width"] = maxWidth + "px";

      // En voor de uitlijning ook de Breadcrumbs
      const breadcrumbElement = document.querySelector("#breadcrumb-container");
      breadcrumbElement.style["max-width"] = maxWidth + "px";

      // En voor het logo
      const logoElement = document.querySelector("#home-link-logo");
      for (const className of logoElement.classList) {
        if (className.startsWith('hidden')) {
          logoElement.classList.remove(className);
        }
      }
      logoElement.classList.add(hiddenClass);
    },
    setContentContainerWidth() {
      if (this.page_block_size > this.originalContentContainerMaxWidth) {
        this.changeContainerPageBlockWidth(this.page_block_size, this.hiddenClass);
      }
    }
  },
  watch: {
    page_block_size() {
      this.setContentContainerWidth();
    }
  }
};
</script>
