import {store} from "@/vue/store";
import {TdrApprovalEscalation} from "@/domain/models/TdrApprovalEscalation";

export class EscalationSettingsController {
  private storeName = 'escalation_settings';

  public async single(escalationId: number): Promise<TdrApprovalEscalation> {
    return await store.dispatch(this.storeName + '/single', {escalationId: escalationId}).then(() => {
      return store.getters[this.storeName + '/state']('single');
    });
  }

  public async formData(locationId: number): Promise<void> {
    await store.dispatch(this.storeName + '/form_field_data', {locationId: locationId});
  }

  public async store(
    locationId: number,
    timeStart: number,
    timeEnd: number,
    selectedDay: number,
    type: number,
    selectedWard: number,
    email: string | null,
    sms: string | null,
    timeoutSeconds: number,
    notification: number,
    selectedCpAction: string | null,
    selectedFaAction: string | null,
    cpWebhook: number | null,
    faWebhook: number | null,
    cpUrl: string | null,
    faUrl: string | null): Promise<void> {
    await store.dispatch(this.storeName + '/create', {
      locationId: locationId,
      data: {
        time_start: timeStart,
        time_end: timeEnd,
        day_number: selectedDay,
        type: type,
        ward_id: selectedWard,
        email_address: email,
        sms_number: sms,
        timeout_seconds: timeoutSeconds,
        app_notification: notification,
        action_cp: selectedCpAction,
        action_fa: selectedFaAction,
        webhook_cp: cpWebhook,
        webhook_fa: faWebhook,
        cp_url: cpUrl,
        fa_url: faUrl,
      }
    }).then(response => {
      store.dispatch('notifications/addSuccessAlert', {
        message: response.data.data.message,
      });
    });
  }

  public async update(
    escalationId: number,
    timeStart: number,
    timeEnd: number,
    selectedDay: number,
    type: number,
    selectedWard: number,
    email: string | null,
    sms: string | null,
    timeoutSeconds: number,
    notification: number,
    selectedCpAction: string | null,
    selectedFaAction: string | null,
    cpWebhook: number | null,
    faWebhook: number | null,
    cpUrl: string | null,
    faUrl: string | null): Promise<void> {
    await store.dispatch(this.storeName + '/update', {
      escalationId: escalationId,
      data: {
        time_start: timeStart,
        time_end: timeEnd,
        day_number: selectedDay,
        type: type,
        ward_id: selectedWard,
        email_address: email,
        sms_number: sms,
        timeout_seconds: timeoutSeconds,
        app_notification: notification,
        action_cp: selectedCpAction,
        action_fa: selectedFaAction,
        webhook_cp: cpWebhook,
        webhook_fa: faWebhook,
        cp_url: cpUrl,
        fa_url: faUrl,
      }
    }).then(response => {
      store.dispatch('notifications/addSuccessAlert', {
        message: response.data.data.message,
      });
    });
  }

  public async remove(escalationId: number): Promise<void> {
    await store.dispatch(this.storeName + '/delete', {escalationId: escalationId}).then(response => {
      store.dispatch('notifications/addSuccessAlert', {
        message: response.data.data.message,
      });
    });
  }
}
