<template>
  <patient-info-medi-modal
      id="intolerances-data-table-modal"
      :patient_id="patient.id"
      :loading="loading"
      title="Allergieën & Intoleranties"
      size="large"
      :min_height="0"
      @closed="close"
  >
    <medimo-external-data-table
        :store_name="store_name"
        :number_of_rows="models.length"
        :refresh_table="refresh_table"
        :search_on_load="false"
        :pagination_enabled="false"
        :no_results_message="no_results_message"
        :name="'patient-intolerances-' + patient_id"
        privacy_filter_property="patient_id"
        :privacy_filter_value="patient_id"
    >
      <medimo-thead>
        <medimo-tr>
          <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="naam">Allergie/intolerantie</medimo-th>
          <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="registerDate">Vaststellingsmoment</medimo-th>
          <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="info">Bijzonderheden</medimo-th>
          <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="stopdat">Stopt per</medimo-th>
        </medimo-tr>
      </medimo-thead>
      <medimo-tbody>
        <template v-for="model in models">
          <intolerances-data-table-row :intolerance="model" :patient="patient" @refresh_table="refresh_table++" />
        </template>
      </medimo-tbody>
    </medimo-external-data-table>

    <template v-slot:action-buttons>
      <medimo-button class="mr-2" @click="openFindZindexIntoleranceModal">Nieuwe toevoegen</medimo-button>
      <medimo-button class="mr-2" @click="updateNoIntolerancesReason" v-if="intolerances === null || intolerances.length === 0">{{ update_reason_button_label }}</medimo-button>
      <medimo-button class="mr-md-auto" type="outline-primary" @click="openHistoryModal">Historie</medimo-button>
      <medimo-button @click="close">Sluiten</medimo-button>
    </template>

    <add-intolerance-modal
        v-if="show_add_intolerance"
        :patient="patient"
        :zindex_intolerance="selected_zindex_intolerance"
        @closed="closeAddIntoleranceModal" />

    <medi-modal v-if="show_history" title="Allergieën/Intoleranties historie" @closed="show_history = false">
      <log-table type="intolerances" :typeId="patient.id" :enable_filter="false" />
    </medi-modal>

    <find-zindex-intolerance-data-table-modal
        v-if="show_search_zindex"
        :patient="patient"
        @closed="show_search_zindex = false"
        @zindexIntoleranceSelected="zindexIntoleranceSelected" />
  </patient-info-medi-modal>
</template>

<script>
import Utility from '@/vue/utility/utility';
import IntolerancesDataTableRow from './IntolerancesDataTableRow';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';
import FindZindexIntoleranceDataTableModal from './FindZindexIntoleranceDataTableModal';
import AddIntoleranceModal from './AddIntoleranceModal';
import intolerances from '@/vue/store/modules/patient/intolerances';
import LogTable from '@/vue/components/log/tables/LogTable';
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MedimoTd from "@/vue/components/general/table/MedimoTd.vue";
import MediModal from "@/vue/components/general/MediModal.vue";

export default {
  emits: ['closed'],
  components: {
    LogTable,
    AddIntoleranceModal,
    IntolerancesDataTableRow,
    PatientInfoMediModal,
    FindZindexIntoleranceDataTableModal, MediModal,
    MedimoExternalDataTable, MedimoTbody, MedimoThead, MedimoTr, MedimoTh, MedimoTd
  },

  props: {
    'patient': {required: true},
  },

  data: function () {
    return {
      store_name: 'intolerances',
      loading: false,
      show_history: false,
      show_search_zindex: false,
      show_add_intolerance: false,
      refresh_table: 0,
      selected_intolerance: false,
      selected_zindex_intolerance: false,
      show_intolerances_button: true,
    };
  },

  computed: {
    patient_id() {
      return this.patient.id;
    },
    models() {
      return this.$store.getters[this.store_name + '/search_results'].filter((model) => {
        return model.patient_id === this.patient_id;
      });
    },
    intolerances: {
      get() {
        return this.$store.getters['intolerances/active'];
      },
      set(value) {
        this.$store.commit('intolerances/set_data', value);
      }
    },
    no_results_message() {
      if (this.intolerances === null) {
        return 'Onbekend of er allergieën/intoleranties zijn';
      } else {
        return 'Geen allergieën/intoleranties aanwezig';
      }
    },
    update_reason_button_label() {
      if (this.intolerances === null) {
        return 'Geen allergieën/intoleranties';
      } else {
        return 'Allergieën/intoleranties onbekend';
      }
    },
  },

  created() {
    let data = Utility.deep_clone(this.$store.state.intolerances.data);
    if (data === null) data = [];
    this.$store.commit(this.store_name + '/set_search_results', data.reverse());
  },

  mounted() {
    // When ready, we set the search endpoint
    this.$store.commit(this.store_name + '/set_search_endpoint', '/api/v1/patients/' + this.patient.id + '/intolerances');
  },

  methods: {
    close() {
      this.$emit('closed');
      this.show_history = false;
    },
    openFindZindexIntoleranceModal() {
      this.show_search_zindex = true;
    },
    zindexIntoleranceSelected(model) {
      this.selected_zindex_intolerance = model;
      this.show_add_intolerance = true;  // open add modal
      this.show_search_zindex = false;      // close find modal
    },
    closeAddIntoleranceModal(refresh) {
      this.show_add_intolerance = false;
      if (refresh) {
        this.refresh_table++;
      }
    },
    openHistoryModal() {
      this.show_history = true;
    },
    updateNoIntolerancesReason() {
      this.$store.dispatch('intolerances/updateNoIntolerancesReason', {
        patient_id: this.patient.id,
        data: {
          reason: this.intolerances === null ? 'known' : 'unknown',
        }
      }).then((result) => {
        this.intolerances = result.intolerances_updated_at === null ? null : [];
      });
    },
  },

  watch: {
    models() {
      this.show_intolerances_button = this.models.length <= 0;
    }
  }
};
</script>
