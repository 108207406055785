<template>
  <medimo-data-table
      :loading="loading"
      :show_loader="show_loader"
      :store_name="store_name"
      :meta_data="meta_data"
      :number_of_rows="internal_number_of_rows"
      :autofocus="autofocus"
      :enable_filter="enable_filter"
      :filter_help_text="filter_help_text"
      :filter_name="filter_name"
      :show_help_text="show_help_text"

      :privacy_filter="privacy_filter"
      :privacy_filter_property="privacy_filter_property"
      :privacy_filter_value="privacy_filter_value"

      v-bind="$attrs"

      :name="name"

      :minimum_height="minimum_height"
      :no_search_message="no_search_message"
      :no_results_message="no_results_message"

      @switch_to_page="switch_to_page($event)"

      :quick_filters="quick_filters"

      extra_buttons_colum="extra_buttons_colum"

      :extra_buttons_class="extra_buttons_class"
      :quick_filter_buttons_class="quick_filter_buttons_class"
      :table_links_class="table_links_class"

      :selection_enabled="selection_enabled"
      @select_all="select_all($event)"
      @deselect_all="deselect_all($event)"

      :overflow="overflow"
      :auto_store_settings="auto_store_settings"
      :auto_trigger_id="auto_trigger_id"
      :doneLoading="doneLoading"

      :isExternalDataTable="true"
      :alignExportButton="alignExportButton"
  >

    <!-- custom elements placed instead of default quick filters -->
    <template v-slot:extra_buttons>
      <slot name="extra_buttons" v-if="$slots['extra_buttons']"></slot>
    </template>

    <slot></slot>

    <template v-slot:select>
      <slot name="select" v-if="$slots['select']"></slot>
    </template>
  </medimo-data-table>
</template>

<script>
import MedimoDataTable from './MedimoDataTable';
import MedimoQuickFilterButton from './MedimoQuickFilterButton';
import MedimoAlert from '../MedimoAlert';
import MedimoFormColumn from '@/vue/components/general/form/base/MedimoFormColumn';
import ThrottlesRequests from '@/vue/mixins/ThrottlesRequests';
import {nextTick} from 'vue';

export default {
  components: {
    MedimoDataTable,
    MedimoQuickFilterButton,
    MedimoAlert,
    MedimoFormColumn,
  },
  emits: ['set_selected_ids'],
  props: {
    'name': {default: ''},

    // If the store_name is not given, use the url to specify the backend
    'store_name': {default: 'local_data_table'},
    'url': {default: null},

    'minimum_height': {default: true},

    // An integer that lets the table know how many rows are rendered in it's slot
    'number_of_rows': {default: null},

    'enable_filter': {default: true},
    'filter_help_text': {default: ''},
    'show_help_text': {default: true},
    'filter_name': {default: 'filtertext'},

    // If this message isset and there is no search available, it will show instead of no_results_message when there are 0 <medimo-tr> rows.
    'no_search_message': {default: false},
    // The standard message to show when no table rows are present
    'no_results_message': {default: 'Geen resultaten gevonden.'},

    // Change this value to refresh the table.
    'refresh_table': {default: 0},

    // Dispatch search call on create
    'search_on_load': {default: true},
    // Show the loader on every search. By default we only show it when there are no rows or the filters change
    // Usable when quickly changing between datatables
    'always_show_loader': {default: false},
    // Search again when the route changes, this is the default to prevent wrong data from being shown
    // However it can also trigger unneeded queries on leaving a page. I leave the default intact, revisit later
    'search_on_route_change': {default: true},
    // If we only want to search again when the variable in the route changes but not the route name, we can specify it here
    'search_on_route_var_change': {default: false},

    // Focust automatisch het zoekveld, maar kan daarmee ook de pagina scrollen
    'autofocus': {default: true},

    // Privacy by Design (frontend filter / weergave, het wordt nog steeds gequeried)
    // Door een property en value mee te geven zoals bijv. patient_id en 12345 filter je _altijd_ op die waardes
    // ongeacht de andere settings. Zo kun je een modellen set altijd juist filteren, ook als per ongeluk nog "oude" data in
    // de store zit
    // By default staat dit aan, door privacy_filter op false te zetten, disable je deze privacy filter bewust.
    'privacy_filter': {
      default: true,
    },
    'privacy_filter_property': {default: null},
    'privacy_filter_value': {default: null},

    // Met deze kunnen we de loader ook handmatig triggeren, na bijvoorbeeld een @event
    'show_loader': {default: false},

    // Met deze kun je de extra buttons div styling. De default opmaak is namelijk niet altijd even mooi
    'quick_filter_buttons_class': {default: 'col-md-6'},
    'table_links_class': {default: 'col-md'},
    'extra_buttons_class': {default: 'col-md'},

    'overflow': {default: 'inherit'},

    // Will show a select and deselect link under the table
    selection_enabled: {default: false},
    selected_ids: {default: null},

    // Zet deze op true om automatisch input van een gebruiker op te slaan
    // zodat deze restored wordt als ze terug navigeren naar deze pagina
    'auto_store_settings': {default: false},
    'auto_trigger_id': {default: null},

    'results_per_page': {default: 10},
    'alignExportButton': {default: true}
  },

  mixins: [
    ThrottlesRequests,
  ],

  data: function () {
    return {
      // A helper variable that tells the ThrottlesRequest whether to show the loader
      // or not, during a request. You don't want to show one for example when you have initial_data
      shouldShowLoaderOnRequest: true,

      loading: false,
      pageJustLoaded: true,
      doneLoading: 0,
    };
  },

  computed: {
    uid() {
      return this._.uid;
    },
    medimoExternalDataTableUid() {
      return this.uid;
    },
    // Used to trigger custom filters in the ApiController
    quick_filters() {
      return this.$store.getters[this.store_name + '/quick_filters'];
    },
    sort_columns() {
      return this.$store.getters[this.store_name + '/sort_columns'];
    },
    query() {
      return this.$store.getters[this.store_name + '/query'];
    },
    meta_data() {
      return this.$store.getters[this.store_name + '/search_meta'];
    },
    is_exporting() {
      return this.$store.getters[this.store_name + '/is_exporting'];
    },
    data_table_identifier() {
      // Same url, but different query would result in same table identifier if we did not include the query.
      // For the patient selector we need different table identifiers when the query differs.
      // Could screw us up later, as the query does not necessarily mean the table data is different..
      return this.$store.getters['settings/data_tables/data_table_identifier'](this.name, this.$route.name, '_QUERY_' + JSON.stringify(this.$route.query));
    },
    storedPage() {
      return this.$store.getters['settings/data_tables/stored_page'](this.data_table_identifier);
    },
    isStored() {
      return typeof this.$store.getters['settings/data_tables/is_stored'](this.data_table_identifier) !== 'undefined';
    },
    records() {
      return this.$store.getters[this.store_name + '/search_results'];
    },
    internal_number_of_rows() {
      return this.number_of_rows !== null ? this.number_of_rows : this.records.length;
    }
  },

  created() {
    if (this.url !== null) {
      this.$store.commit(this.store_name + '/clear_search_results');
      this.$store.commit(this.store_name + '/set_search_endpoint', this.url)
    }
  },

  unmounted() {
    if (this.url !== null) {
      this.$store.commit(this.store_name + '/clear_search_results');
      this.$store.commit(this.store_name + '/set_search_endpoint', null)
    }
  },

  mounted() {
    // Fetch results and show the loader when there are no rows.
    // Alleen triggeren als er geen saved settings zijn, anders doen de watchers dat
    if (this.search_on_load && !this.isStored) {
      nextTick(() => {
        // Als alles klaar is, gaan we pas requesten
        this.searchPage(this.storedPage, this.internal_number_of_rows === 0);
      });
    }
    setTimeout(() => {
      // De eerste seconde na pageload willen we evt opgeslagen settings loaden
      // Als dan bijv de query automatisch ge-set wordt, willen we direct loaden met de opgeslagen pagina, en niet pagina 1
      this.pageJustLoaded = false;
    }, 1000);
    //
  },

  methods: {
    searchPage(page_number, show_loader = true) {
      this.loading = show_loader || this.always_show_loader;
      // On load worden bij het restoren van settings door de watchers dan mogelijk 3x searches verstuurd
      // door de query restore, sort columns restore, en quick filter restore. Met deze 50ms delay
      // gaat er maar 1 naar de backend
      this.dispatchThrottled(this.store_name + '/search', {
        page: page_number,
        results_per_page: this.results_per_page,
      }, 150).then(() => {
        this.loading = false;
        this.doneLoading++;
      }).catch(error => {
        if (error.message !== 'Throttled') {
          // Hier kan potentieel iets afgevangen worden op basis van error.response.status
          this.loading = false;
        }
      });
    },
    switch_to_page(page_number) {
      // Switch pages instantly
      this.searchPage(page_number, true);
    },
    select_all() {
      this.shouldShowLoaderOnRequest = false;
      this.dispatchThrottled(this.store_name + '/select', {}, 0).then(response => {
        const currentSelectedIds = this.selected_ids;
        for (const id of response.data) {
          // Only add ID when not yet in selection
          if (!currentSelectedIds.includes(id)) {
            currentSelectedIds.push(id);
          }
        }
        this.$emit('set_selected_ids', currentSelectedIds);
      }).catch(error => {
        if (error.message !== 'Throttled') {
          // nothing we can do
          this.$root.sendSentry(error);
        }
      });
    },
    deselect_all() {
      this.shouldShowLoaderOnRequest = false;
      this.dispatchThrottled(this.store_name + '/select', {}, 0).then(response => {
        let currentSelectedIds = this.selected_ids;
        // Loop through found ids, and deselect them bij looking if they are in the list of current selected ids.
        for (const id of response.data) {
          currentSelectedIds = currentSelectedIds.filter(function (item) {
            return item !== id;
          });
        }
        this.$emit('set_selected_ids', currentSelectedIds);
      }).catch(error => {
        if (error.message !== 'Throttled') {
          // nothing we can do
          this.$root.sendSentry(error);
        }
      });
    },
  },

  watch: {
    query(value) {
      // We start the search after 500ms of no input
      this.loading = false;

      // Cancellen bij input change sowieso de laatste
      this.$store.dispatch(this.store_name + '/abortSearch');

      setTimeout(() => {
        // When the query changes, we reset the page to X (which auto-reloads the data as well)
        // And set loading to true, so it always shows the loader on a query Search
        if (value === this.query) {
          // Als de pagina net geladen is gebruiken we de opgeslagen pagina
          // Zo niet, dan is er user input geweest en re-setten we bij het wijzigen van de query altijd naar pagina 1
          this.searchPage(this.pageJustLoaded ? this.storedPage : 1);
        }
      }, 500);
    },
    sort_columns: {
      deep: true,
      handler() {
        // When the sort_columns change, refetch the data, fast
        this.searchPage(this.pageJustLoaded ? this.storedPage : 0, true);
      }
    },
    quick_filters: {
      deep: true,
      handler() {
        // When the quick_filters change, refetch the data, fast
        this.searchPage(this.pageJustLoaded ? this.storedPage : 0, true);
      }
    },
    is_exporting(is_exporting) {
      // Als deze op true gaat, dan zijn we aan het exporteren en moet hij getriggerd worden.
      this.loadingThrottled = is_exporting;
    },
    // Used to reset the data after a route change, preventing old data from being shown.
    '$route': {
      deep: true,
      handler(newVal, oldVal) {
        const varChanged = newVal.name === oldVal.name && JSON.stringify(newVal.params) !== JSON.stringify(oldVal.params);

        if (this.search_on_route_change || (this.search_on_route_var_change && varChanged)) { // See property comment
          // When the route changes, refetch the data, fast
          this.$store.commit(this.store_name + '/clear_search_results');
          this.searchPage(1, true);
        }
      }
    },
    refresh_table() {
      // Refresh the data and go back to page 1.
      this.searchPage(1, true);
    }
  }
};
</script>
