import BaseModule from '@/vue/store/modules/base_module';

const state = {
  ...BaseModule.state,
  locationName: '',
  single: {},
  days: [],
  wards: [],
  webhooks: [],
  sort_columns: [{column_name: 'formattedWard', direction: 'ASC'}],
};

// getters
const getters = {
  ...BaseModule.getters
};

// actions
const actions = {
  ...BaseModule.actions,
  single({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/agentsqueue/escalation-settings/single/' + payload.escalationId,
    }, {root: true}).then(response => {
      commit('set_state', {property: 'single', value: response.data.data});
    });
  },
  fetch_all({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/agentsqueue/escalation-settings/' + payload.locationId,
    }, {root: true}).then(response => {
      commit('set_data', response.data.data);
      commit('set_state', {property: 'locationName', value: response.data.locationName});
    });
  },
  create({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/agentsqueue/escalation-settings/store/' + payload.locationId,
      data: payload.data
    }, {root: true});
  },
  update({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/postEndpoint', {
      endpoint: '/api/v1/agentsqueue/escalation-settings/update/' + payload.escalationId,
      data: payload.data,
    }, {root: true});
  },
  delete({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/deleteEndpoint', {
      endpoint: '/api/v1/agentsqueue/escalation-settings/delete/' + payload.escalationId,
    }, {root: true});
  },
  form_field_data({state, commit, dispatch, getters, rootState}, payload) {
    return dispatch('api/getEndpoint', {
      endpoint: '/api/v1/agentsqueue/escalation-settings/form-field-data/' + payload.locationId,
    }, {root: true}).then(response => {
      commit('set_state', {property: 'locationName', value: response.data.locationName});
      commit('set_state', {property: 'days', value: response.data.days});
      commit('set_state', {property: 'wards', value: response.data.wards});
      commit('set_state', {property: 'webhooks', value: response.data.webhooks});
    });
  },
};

// mutations
const mutations = {
  ...BaseModule.mutations,
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
