<template>
  <MediModal
      class="find-zindex-readings-table"
      title="Zoek bepaling om toe te voegen"
      size="large"
      :min_height="0"
      @closed="close"
  >
    <MedimoExternalDataTable
        :name="'patient-bepaling-' + patient.id"
        ref="dataTable"
        :store_name="store_name"
        :privacy_filter="false"
    >
      <MedimoThead>
        <MedimoTr>
          <MedimoTh column_classes="col col-sm-3" :store_name="store_name" sort_column_1="code">Code</MedimoTh>
          <MedimoTh column_classes="col col-sm-9" :store_name="store_name" sort_column_1="reading">Bepaling</MedimoTh>
        </MedimoTr>
      </MedimoThead>
      <MedimoTbody>
        <template v-for="model in dataTable?.records">
          <FindBepalingDataTableRow :bepaling="model" @selected="emit('bepalingSelected', model);" />
        </template>
      </MedimoTbody>
    </MedimoExternalDataTable>
  </MediModal>
</template>

<script setup lang="ts">
import FindBepalingDataTableRow from './FindBepalingDataTableRow.vue';
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MediModal from "@/vue/components/general/MediModal.vue";
import {Patient} from "@/domain/models/Patient";
import {onMounted} from "@vue/runtime-core";
import {store} from "@/vue/store";
import {ref} from "vue";

const emit = defineEmits(['bepalingSelected', 'closed']);

interface Props {
  patient: Patient
}

const props = defineProps<Props>();
const dataTable = ref();
const store_name = 'readings_bepalingen';

onMounted(() => {
  store.commit(store_name + '/set_search_endpoint', '/api/v1/patients/' + props.patient.id + '/readings/bepalingen');
});

function close() {
  emit('closed');
}

</script>
