<template>
  <div :medimo-table-uid="table_id" :class="'medimo-table ' + (minimum_height ? 'm-height' : 'no-m-height')" :style="'position: relative; overflow: ' + overflow + ';'">
    <medimo-loader-overlay transition="" :size="32" :loading="loading"></medimo-loader-overlay>
    <div class="row">
      <div class="col-md-3" v-if="enable_filter">
        <medimo-form class="datatable-query-form mb-2 mr-2">
          <medimo-input input_class="table-filter"
                        label="Filter"
                        :name="filter_name"
                        placeholder="Zoek / filter"
                        ref="table-filter"
                        :help_text="show_help_text ? filter_help_text : ''"
                        v-model="query"
                        @focus="focusTable"
                        @keydown_backspace="backspace"
          >
          </medimo-input>
          <slot name="store_settings"></slot>
        </medimo-form>
      </div>
      <div :class="quick_filter_buttons_class" v-if="$slots.quick_filter_buttons">
        <div class="row h-100 quick-filter-buttons">
          <slot name="quick_filter_buttons"></slot>
        </div>
      </div>
      <div :class="table_links_class" v-if="$slots.table_links">
        <div class="row h-100 table-links">
          <slot name="table_links"></slot>
        </div>
      </div>
      <div :class="extra_buttons_class" v-if="$slots.extra_buttons">
        <div class="row h-100 extra-buttons">
          <slot name="extra_buttons"></slot>
        </div>
      </div>
    </div>

    <div class="table table-striped table-borderless" :ref="table_id" v-bind:class="{'disabled': read_only}">

      <medimo-tr-blank-copy-row></medimo-tr-blank-copy-row>
      <!-- We use classes to hide the tbody in the <slot> when there are no rows -->
      <!-- Om de content 100% te resetten moeten we hem even uit/aan zetten -->
      <div ref="medimo-table-slot" class="medimo-table-content" v-bind:class="{'no-rows': number_of_rows === 0}">
        <!-- This contains the regular table rows and cells -->
        <slot></slot>
      </div>

      <!-- Auto No-Result Message -->
      <!-- Shown when there are 0 <medimo-tr>'s in the <slot>. -->
      <div class="tbody" v-if="number_of_rows === 0">
        <medimo-tr class="text-center no-styling" :keynav="false" :disabled="true">
          <medimo-td v-if="showNoSearchMessage">{{ no_search_message }}</medimo-td>
          <medimo-td v-else>{{ no_results_message }}</medimo-td>
        </medimo-tr>
      </div>
    </div>

    <slot name="pagination"></slot>

    <slot name="select"></slot>

  </div>
</template>

<script>
import MedimoButtonLink from '../MedimoButtonLink';
import MedimoLoaderOverlay from '../MedimoLoaderOverlay';
import MedimoTr from './MedimoTr';
import MedimoTd from './MedimoTd';

import MedimoLabeledInput from '@/vue/components/general/form/base/MedimoLabeledInput';
import MedimoInput from '@/vue/components/general/form/base/MedimoInput';
import MedimoForm from '@/vue/components/general/form/MedimoForm';
import MedimoTrBlankCopyRow from './MedimoTrBlankCopyRow';
import WorksWithParentsSiblingsAndChildren from '@/vue/mixins/WorksWithParentsSiblingsAndChildren';
import { nextTick } from 'vue';

export default {
  components: {
    MedimoButtonLink,
    MedimoLabeledInput,
    MedimoInput,
    MedimoForm,
    MedimoTrBlankCopyRow,
    MedimoLoaderOverlay,
    MedimoTr,
    MedimoTd,
  },

  mixins: [
    WorksWithParentsSiblingsAndChildren,
  ],

  props: {
    // If this message isset and there is no search available, it will show instead of no_results_message when there are 0 <medimo-tr> rows.
    'no_search_message': {default: false},
    // Change this one to edit the message that's shown when there are 0 <medimo-tr> rows.
    'no_results_message': {default: 'Geen resultaten gevonden'},

    // Specify an action if you need more flexibility, for example when there are nested models that
    // you'd like to search as well
    'set_query_action_name': {default: ''},

    // When using data from a store, setting this links it up to the store's query value
    'store_name': {default: ''},

    'read_only': {default: false}, // Set this to false to disable (visually) all rows in the table

    'enable_filter': {default: true},
    // Set to a string to show
    'filter_help_text': {default: ''},
    'show_help_text': {default: true},
    'filter_name': {default: 'filtertext'},

    'name': {default: ''},

    'filter_fields': {
      default: () => {
        return [];
      }
    }, // Array with column names to filter

    'enable_links': {default: true},

    // Set to false to disable the Copy button with which you can copy the table contents.
    // Individual properties on medimo-td and medimo-th allow for fine-grain control over
    // what content is / isn't copied to the clipboard
    'copyable': {default: false},

    'tabindex': {default: '0'},
    'autofocus': {default: true},
    'can_be_focused': {default: true},

    'loading': {default: false},

    // Set to TRUE to give the MedimoTable a minimum height
    'minimum_height': {default: false},

    // An integer that lets the table know how many rows are rendered in it's slot
    'number_of_rows': {default: 0, required: true},

    /**
     * The Meta data, corresponds to the Larvel Resource meta structure.
     * Contains the amount of pages, total results, etc.
     */
    'meta_data': {
      default: () => {
        return {};
      }
    },

    'quick_filter_buttons_class': {default: 'col-md-6'},
    'table_links_class': {default: 'col-md'},
    'extra_buttons_class': {default: 'col-md'},

    'overflow': {default: 'inherit'},

    'doneLoading': {default: 0},
    'auto_trigger_id': {default: null},

    isExternalDataTable: {default: false},
    isLocalDataTable: {default: false},
  },

  data: function () {
    return {
      // Used to track and allow the navback on backspace
      allowNavBackOnBackspace: true,
      preventNavBackOnBackspaceTimeout: null,
    };
  },

  computed: {
    query: {
      get() {
        if (this.store_name.length) {
          return this.$store.getters[this.store_name + '/query'];
        }
        return '';
      },
      set(value) {
        if (this.set_query_action_name.length > 0) {
          this.$store.dispatch(this.set_query_action_name, value);
        } else {
          this.$store.commit(this.store_name + '/set_query', value);
        }
      }
    },
    uid() {
      return this._.uid;
    },
    table_id() {
      return 'table' + this.uid;
    },
    medimoTableUid() {
      return this.uid;
    },
    isTheActiveTable() {
      return this.$store.getters['settings/navigation/activeTableUid'] === this.uid;
    },
    navSearchIsOpen() {
      return this.$store.state.settings.navigation.navSearchNavigation;
    },
    // Om er zeker van te zijn dat de keynav werkt wanneer hij moet werken, moeten we ook rekening
    // houden met de gelaagdheid van de UI door de MediModals. Als er een MediModal open is
    // en deze table zit er niet in, werkt de keynav niet
    mediModalParent() {
      return this.find_matching_parent('mediModalUid');
    },
    isInMediModal() {
      // Als hij een MediModal parent gevonden heeft in z'n parent hierarchy dan zit hij er in
      return typeof this.mediModalParent !== 'undefined';
    },
    isInActiveMediModal() {
      if (!this.isInMediModal) {
        return false;
      }
      return this.mediModalParent.isTopModal;
    },
    mediModalsAreOpen() {
      return this.$store.getters['settings/modals/are_open'];
    },
    showNoSearchMessage() {
      return this.query === '' && this.no_search_message !== false;
    },
    sort_columns() {
      return this.$store.getters[this.store_name + '/sort_columns'];
    },
    selectedTableRowUid() {
      return this.$store.state.settings.navigation.selectedTableRowUid[this.uid];
    },
  },

  created() {
    // We voegen hem toe aan de beschikbare MedimoTables
    this.$store.commit('settings/navigation/addToAvailableTables', this.uid);

    window.addEventListener('keydown', this.navigate);
  },

  mounted() {
    // En refreshen de rows die beschikbaar zijn:
    // Auto focus the table filter
    if (this.autofocus && this.number_of_rows) {
      // Nadat de DOM gerendert is
      nextTick(() => {
        if (!this.isExternalDataTable) {
          this.populateAvailableTableRows();
        }
        this.focusTable();
      });
    }
  },
  beforeUnmount() {
    this.$store.commit('settings/navigation/removeFromActiveTableUids', this.uid);
    this.$store.commit('settings/navigation/removeFromAvailableTables', this.uid);
    this.$store.commit('settings/navigation/removeAvailableTableRows', this.uid);
    window.removeEventListener('keydown', this.navigate);
  },

  methods: {
    navigate(event) {
      // If not the active table, ignore input.
      if (!this.isTheActiveTable || this.navSearchIsOpen) {
        return false;
      }

      // Als de MedimoTable actief is, maar in een MediModal zit die niet bovenaan zit
      // dan negeren we ook de input
      if (this.isInMediModal && !this.isInActiveMediModal) {
        return false;
      }
      // En uiteraard ook... als hij _niet_ in een MediModal zit, maar er wel MediModals open zijn:
      if (!this.isInMediModal && this.mediModalsAreOpen) {
        return false;
      }

      if (event.keyCode == '38') {
        // up arrow
        event.preventDefault();
        this.goUp();

      } else if (event.keyCode == '40') {
        // down arrow
        event.preventDefault();
        this.goDown();
      } else if (event.keyCode == '13') {
        event.preventDefault();
        // Enter
        // Maar als hij aan het loaden is negeren we deze:
        if (this.loading) {
          return false;
        }
        // When pressing enter, we trigger the Trigger on the selected MedimoTr
        this.$store.commit('settings/navigation/triggerSelectedTableRow');
      }
    },
    backspace(event) {
      if (this.query.length === 0 && this.allowNavBackOnBackspace) {
        this.$router.go(-1);
      }
    },
    goDown() {
      this.focusFilter();
      this.$store.dispatch('settings/navigation/selectNextTableRow', this.uid);
    },
    goUp() {
      this.focusFilter();
      this.$store.dispatch('settings/navigation/selectPreviousTableRow', this.uid);
    },
    focusTable() {
      // Zie created() en de data comments.
      if (!this.can_be_focused) {
        return;
      }
      // Set this table as the active one
      this.$store.commit('settings/navigation/addToActiveTableUids', this.uid);

      this.focusFilter();
    },
    focusFilter() {
      if (this.isTheActiveTable && this.enable_filter) {
        nextTick(() => {
          this.$refs['table-filter'].$el.querySelector('input').focus();
        });
      }
    },
    populateAvailableTableRows() {
      // Missing forEach on NodeList for IE11 - Sentry 39640
      if (window.NodeList && !NodeList.prototype.forEach) {
        NodeList.prototype.forEach = Array.prototype.forEach;
      }

      if (!this.$refs['medimo-table-slot'] === null) {
        return;
      }

      const currentAvailableTableRowUids = [];

      // Eerst setten we de basis (een lege array)
      // And then replace the array in one go to prevent flickering
      this.$store.commit('settings/navigation/setAvailableTableRows', {
        tableUid: this.uid,
        rowUids: currentAvailableTableRowUids,
      });

      // Eerst halen we de MedimoTbody op:
      const medimoTbody = this.$refs['medimo-table-slot'].querySelector('.medimo-tbody');
      if (medimoTbody === null) {
        if (process.env.APP_ENV === 'development' || process.env.APP_ENV === 'testing') {
          console.warn('No <medimo-t-body> child found for <' + this.$options._componentTag + '> component. Unable to enable keynav');
        }
        return;
      }

      // Als we de children rechtstreeks gebruiken klopt de volgorde niet, die
      // moeten we dus echt uit de shadow DOM halen, maar wel via $refs zodat
      // het "binnen" Vue blijft
      let availableRowsInOrder = [];
      availableRowsInOrder = this.$refs['medimo-table-slot'].querySelector('.medimo-tbody').querySelectorAll('.medimo-tr');

      availableRowsInOrder.forEach(tableRow => {
        currentAvailableTableRowUids.push(parseInt(tableRow.getAttribute('uid')));
      });

      // And then replace the array in one go to prevent flickering
      this.$store.commit('settings/navigation/setAvailableTableRows', {
        tableUid: this.uid,
        rowUids: currentAvailableTableRowUids,
      });

      this.selectFirstTableRow();
    },
    selectFirstTableRow() {
      // Hier geven we de MedimoTrs even tijd om te renderen en de availableTableRows te vullen
      // in de navigation store
      setTimeout(() => {
        // Alleen als hij 0 is selecteren we de eerste
        // Anders kan het zijn dat in de MedimoTr met de auto_select_id al een andere row gekozen is
        // Of als de auto_trigger_null is, dan willen we altijd de eerste selecteren
        if (!this.auto_trigger_id) {
          this.$store.commit('settings/navigation/selectFirstTableRow', this.uid);
        }
      }, 50);
    },
  },

  watch: {
    // This one makes sure that if we change the active table from elsewhere, it's still in proper focus
    // ...but only when the filter is actually enabled ofcourse
    isTheActiveTable(isTheActiveTable) {
      // Hier focussen we de tabel  opnieuw als hij actief wordt, waardoor de eerste MedimoTr
      // geselecteerd wordt en de query filter
      if (isTheActiveTable) {
        this.focusTable();
      }
    },
    loading(loading) {
      // When loading stops, we re-focus the table
      if (loading === false && this.autofocus) {
        nextTick(() => {
          this.focusTable();
        });
      }
    },
    doneLoading() {
      nextTick(() => {
        this.populateAvailableTableRows();
      });
    },
    // When the number of rows changes, re-focus the table
    number_of_rows() {
      this.focusTable();
      this.selectFirstTableRow();
    },
    query(value) {
      if (this.isLocalDataTable) {
        nextTick(() => {
          this.populateAvailableTableRows();
        });
      }

      if (value.length === 0) {
        clearTimeout(this.preventNavBackOnBackspaceTimeout);
        this.preventNavBackOnBackspaceTimeout = setTimeout(() => {
          this.allowNavBackOnBackspace = true;
        }, 1000);
      } else {
        this.allowNavBackOnBackspace = false;
      }
    },
    sort_columns: {
      deep: true,
      handler() {
        // Als de sort columns veranderen (en daarmee dus de volgorde zelf)
        // moeten we in het geval van een MedimoLocalDataTable de availableTableRows
        // herschikken, waar dat bij de MedimoExternalDataTable automatisch gaat
        // door de API call
        if (this.isLocalDataTable) {
          setTimeout(() => {
            nextTick(() => {
              this.populateAvailableTableRows('sort_columns');
            });
          }, 50);
        }
      }
    }
  }
};
</script>
