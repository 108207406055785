<template>
  <MedimoExternalDataTable
      store_name="log"
      :name="'log-table-' + type + '-' + typeId"
      ref="dataTable"
      :search_on_load="true"
      :autofocus="true"
      :enable_filter="enable_filter"
      :privacy_filter="false"
  >
    <MedimoThead>
      <MedimoTr>
        <MedimoTh sort_column_1="created_at" column_name_1="Log" store_name="log" />
      </MedimoTr>
    </MedimoThead>
    <MedimoTbody>
      <template v-for="log in dataTable?.records">
        <MedimoTr :keynav="false" :disabled="true" :inactive="true">
          <MedimoTd>
            <template v-for="dataLine in log.data">
              <MedimoFormattedText type="div" :text="dataLine" />
            </template>
            {{ log.by }}
          </MedimoTd>
        </MedimoTr>
      </template>
    </MedimoTbody>
  </MedimoExternalDataTable>
</template>

<script setup lang="ts">
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MedimoTd from "@/vue/components/general/table/MedimoTd.vue";
import {onBeforeMount, ref} from "vue";
import {store} from "@/vue/store";
import MedimoFormattedText from "@/vue/components/general/MedimoFormattedText.vue";

const dataTable = ref();

interface Props {
  type: string,
  typeId?: number | null,
  enable_filter?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  type: undefined,
  typeId: null,
  enable_filter: true,
});

onBeforeMount(() => {
  store.commit('log/clear_search_results');
  store.dispatch('log/set_endpoint', {type: props.type, typeId: props.typeId});
  store.commit('log/set_state', { property: 'sort_columns', value: [{column_name: 'created_at', direction: 'DESC'}] });
});
</script>

<style lang="scss" scoped>
.tr:hover {
  cursor: auto !important;
  .td {
    cursor: auto !important;
  }
}
</style>
