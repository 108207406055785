<template>
    <medimo-labeled-input
        :validation_class="cancelled ? 'is-invalid' : ''"
        :ref="'input' + uid"
        :name="name"
        :id="id"
        :modelValue="localValue"
        :extra_styles="extra_styles"
        :class="{invalid: invalid}"
        @update:modelValue="onInput($event)"
        @blur="onBlur($event)"
        @focus="onFocus()"
        :focus="focus"
        :hide_zero="hide_zero"
        :clear_on_click="clear_on_click"
        :disabled="disabled"
        :label="label"
        :label_column_classes="label_column_classes"
        :help_text="help_text"
        @keydown_backspace="$emit('keydown_backspace', $event)" @keydown_tab="$emit('keydown_tab', $event)" @keydown_enter="$emit('keydown_enter', $event)" @keydown_left="$emit('keydown_left', $event)" @keydown_up="$emit('keydown_up', $event)" @keydown_right="$emit('keydown_right', $event)" @keydown_down="$emit('keydown_down', $event)" @keydown_period="$emit('keydown_period', $event)" @keyup_backspace="$emit('keyup_backspace', $event)" @keyup_tab="$emit('keyup_tab', $event)" @keyup_enter="$emit('keyup_enter', $event)" @keyup_left="$emit('keyup_left', $event)" @keyup_up="$emit('keyup_up', $event)" @keyup_right="$emit('keyup_right', $event)" @keyup_down="$emit('keyup_down', $event)" @keyup_period="$emit('keyup_period', $event)"
    ></medimo-labeled-input>
</template>

<style>
    .invalid {
        border-color: red;
        border-radius: 3px;
        background: red;
    }
</style>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';

export default {
  emits: ['blur',  'keydown_backspace', 'keydown_tab', 'keydown_enter', 'keydown_left', 'keydown_up', 'keydown_right', 'keydown_down', 'keydown_period', 'keyup_backspace', 'keyup_tab', 'keyup_enter', 'keyup_left', 'keyup_up', 'keyup_right', 'keyup_down', 'keyup_period'],
  components: {},

  props: {
    value: {default: 0},
    index: {default: true},
    default_value: {default: 0},
    name: {default: 'base_dosage_input'},
    extra_styles: {default: ''},
    disabled: {required: false, default: false},
    id: {default: ''},
    focus: {default: false},
    hide_zero: {default: true},
    clear_on_click: {default: false},
    cancelled: {default: false},

    help_text: {default: ''},
    label: {default: ''},
    label_column_classes: {default: ''},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      localValue: 0,
    };
  },

  computed: {
    invalid() {
      return false;
    },
    uid() {
      return this._.uid;
    }
  },

  created() {
    this.localValue = this.value;
  },

  mounted() {
    //
  },

  methods: {
    onInput: function (value) {
      this.localValue = value;
    },
    onBlur: function (event) {
      this.localValue = this.sanitize(event);
      const realValue = this.localValue === '' ? '0' : this.localValue; // Empty string is ok to show, but use 0 for calculations.
      if (this.value !== realValue) {
        this.$emit('blur', realValue); // Only emit on change.
      }
    },
    sanitize: function (value) {
      value = '' + value; //Force type to String
      value = this.sanitizeZeroes(value);
      value = this.sanitizeDots(value);
      value = this.sanitizeSpaces(value);
      value = this.sanitizeDecimals(value);
      if (isNaN(value)) { // Note: Empty string returns false for isNaN! So '' will NOT be converted to 'x' here.
        value = 'x';
      }

      return value;
    },
    sanitizeZeroes(value) {
      return value.replace(/^0+/, '');
    },
    sanitizeDots: function (value) {
      value = value.replace(',', '.');
      // Replace one or more dots with one dot
      value = value.replace(/\.{2,}/g, '.');

      // If the first character is a dot, prefix it with 0.
      if (value[0] === '.') {
        value = '0' + value;
      }

      return value;
    },
    sanitizeSpaces: function (value) {
      return value.replace(/\s+/g, '');
    },
    sanitizeDecimals: function (value) {
      // if there is a dot, trim all zero's and/or dots from the end
      if (value.includes('.')) {
        value = value.replace(/\.*0*$/, '');
      }
      return value;
    },
    onFocus() {
    }
  },

  watch: {
    value(val) {
      // De "amount" die terug komt vanuit /api/v1/dosage-instruction/calculate/amount
      // kan ook leeg / null zijn. Zie DosageInstructionController::calculateAmount().
      // Dus niks te sanitizen. Dus moet hij dan terug naar de default: 0
      // https://sentry.medimo.nl/organizations/sentry/issues/4824/?environment=production&project=3&query=is%3Aunresolved
      if (val === null) {
        this.localValue = '0';
      } else {
        const sanitizedValue = this.sanitize(val.toString());
        if (sanitizedValue === '' && !this.disabled) {
          this.localValue = '0'; // When this dosage input becomes enabled, there is nu blur event that converts '' to '0'. So we have to do it manually here.
        } else {
          this.localValue = sanitizedValue;
        }
      }
    }
  }
};
</script>
