<template>
  <medi-modal
      class="find-zindex-episode-table"
      id="find-zindex-episode-table"
      title="Zoek episode om toe te voegen"
      size="large"
      :min_height="0"
      @closed="close"
  >
    <medimo-external-data-table
        :store_name="store_name"
        :name="'patient-zindex-episodes-' + patient.id"
        ref="dataTable"
        :privacy_filter="false"
    >
      <medimo-thead>
        <medimo-tr>
          <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="ICPC1">Code</medimo-th>
          <medimo-th column_classes="col col-sm-9" :store_name="store_name" sort_column_1="ICPCTXT">Omschrijving</medimo-th>
        </medimo-tr>
      </medimo-thead>
      <medimo-tbody>
        <template v-for="model in dataTable?.records">
          <find-zindex-episode-data-table-row :episode="model" @selected="$emit('zindexEpisodeSelected', model)" />
        </template>
      </medimo-tbody>
    </medimo-external-data-table>

  </medi-modal>
</template>
<script setup lang="ts">
import FindZindexEpisodeDataTableRow from './FindZindexEpisodeDataTableRow.vue';
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MediModal from "@/vue/components/general/MediModal.vue";
import {ref} from "vue";
import {onMounted} from "@vue/runtime-core";
import {store} from "@/vue/store";
import {Patient} from "@/domain/models/Patient";

const dataTable = ref();
const store_name = 'episodes_zindex';

const emit = defineEmits(['zindexEpisodeSelected','closed']);

interface Props {
  patient: Patient
}

const props = defineProps<Props>();

onMounted(() => {
  // When ready, we set the search endpoint
  store.commit(store_name + '/set_search_endpoint', '/api/v1/patients/' + props.patient.id + '/episodes/search-icpc');
});

function close() {
  emit('closed');
}
</script>
