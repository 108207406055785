<template>
    <master-layout breadcrumb_title="Cluster Select">
        <page-block id="cluster-select">
            <div class="h1 mb-2">Selecteer uw cluster</div>
            <medimo-external-data-table
                    store_name="clusters"
                    name="cluster-selector-datatable"
                    :show_loader="loading"
                    :privacy_filter="false"
            >
                <medimo-thead>
                    <medimo-tr>
                        <medimo-th column_classes="col-12 col-sm-7" store_name="clusters" column_name_1="Naam" sort_column_1="name" />
                        <medimo-th column_classes="col-12 col-sm-5" store_name="clusters" column_name_1="Plaats" sort_column_1="city" />
                    </medimo-tr>
                </medimo-thead>
                <medimo-tbody v-if="clusters_data.length" id="cluster-select-body">
                    <cluster-selector-row
                            @startLoading="loading = true"
                            @stopLoading="loading = false"
                            :returnPath="returnPath"
                            :key="cluster.id"
                            :cluster="cluster" v-for="cluster in clusters_data"
                    ></cluster-selector-row>
                </medimo-tbody>
            </medimo-external-data-table>
        </page-block>
    </master-layout>
</template>

<script>
import MasterLayout from '@/vue/pages/__layouts__/__MasterLayout__.vue';
import PageBlock from '@/vue/components/general/layout/PageBlock.vue';
import ClusterSelectorRow from './ClusterSelectorRow.vue';
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";

export default {
  components: {
    MasterLayout,
    PageBlock,
    MedimoExternalDataTable,
    MedimoTbody,
    MedimoThead,
    MedimoTr,
    MedimoTh,
    ClusterSelectorRow
  },

  props: {
    // Used to redirect the user after a successful Cluster switch
    returnPath: {default: ''},
  },

  data: function () {
    return {
      loading: false,
    };
  },

  computed: {
    clusters_data() {
      return this.$store.state.clusters.search_results;
    },
    session_cluster_data() {
      return this.$store.state.current_user.data.clusters;
    }
  },

  created() {
    if (this.session_cluster_data.data.length > 0) {
      this.$store.commit('clusters/set_search_results', this.session_cluster_data.data);
      this.$store.commit('clusters/set_search_meta', this.session_cluster_data.meta);
    }
  },
};
</script>
