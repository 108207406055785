<template>
    <medimo-tr class="dosage-instruction" v-bind:class="{'disabled': readOnly, 'is-clickable': !readOnly}" v-if="show"
               @triggered="navigate"
               :keynav="false"
    >
        <!-- Tablet + -->
        <medimo-td :class="'d-sm-none d-md-block d-print-block ' + medicatieColumnClasses">
            <div class="row no-gutters text-small-sm">
                <div class="col-sm-1 col-print-1 col-12 d-none d-sm-block d-print-block">
                    <span>
                        {{ dosage_instruction.usage_type }}
                    </span>
                </div>
                <div class="col-sm-11 col-print-11 col-12" v-bind:class="{'h5': currentlyOn('sm')}">
                    <span>{{ dosage_instruction.drug_name }}</span>
                    <span class="text-danger text-small m-1">{{drug.attributes_short}}</span>
                </div>
            </div>
        </medimo-td>
        <medimo-td class="col-12 d-print-none" v-if="currentlyOn('sm')">
            <div class="row no-gutters text-small-sm">
                <div class="col-3" v-if="currentlyOn('sm')">
                    Type
                </div>
                <div class="col-9 col-sm-12">
                    {{ dosage_instruction.usage_type }}
                </div>
            </div>
        </medimo-td>
        <medimo-td :class="doseringColumnClasses + ' col-12'">
            <div class="row no-gutters text-small-sm">
                <div class="col-3 d-sm-block d-md-none d-print-none">
                    Dosering
                </div>
                <div class="col-9 col-print-12 col-sm-12">
                    {{dosage_to_string}}
                    <span class="text-dark text-small" v-if="dosage_instruction.info"><br>{{ dosage_instruction.info }}</span>
                </div>
            </div>
        </medimo-td>
        <medimo-td :class="ingangPerColumnClasses + ' col-12'" v-if="show_ingang_per">
            <div class="row no-gutters">
                <div class="col-sm-10 col-print-10 col-12">
                    <div class="row no-gutters text-small-sm" v-if="show_ingang_per">
                        <div class="col-3 d-sm-block d-md-none d-print-none">
                            Ingang per
                        </div>
                        <div class="col-9 col-print-12 col-sm-12">
                          <span class="status badge badge-sm badge-start mr-1" v-if="noticeStart">START</span>
                          <span v-if="noticeStart || ! parsedStopDateTime">
                                {{ parsedStartDate }}
                            </span>
                          <span v-if="noticeStart && parsedStopDateTime">
                                <br/>
                            </span>
                          <span v-if="parsedStopDateTime">
                            <medimo-tooltip :content="stopTypeDescription">
                                <span class="status badge badge-sm badge-stop mr-1">{{ stopType }}</span>
                                {{ parsedStopDateTime }}
                            </medimo-tooltip>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="col-sm-2 col-print-2 col-12 td p-0">
                    <schema-button :show_all="show_all" @click="$emit('toggle_show_all', '')"
                                   v-if="!this.readOnly && has_future_instructions && isFirstDosageInstruction && all_dosage_instruction_ids.length > 1"
                    ></schema-button>
                </div>
            </div>
        </medimo-td>
        <slot name="extra_columns"></slot>
    </medimo-tr>
</template>

<script>
import moment from 'moment-mini';
import Dates from '@/vue/utility/dates';
import IsPageNavItem from '@/vue/mixins/IsPageNavItem';
import MedimoTr from '@/vue/components/general/table/MedimoTr';
import MedimoTd from '@/vue/components/general/table/MedimoTd';
import NavigatesProgrammatically from '@/vue/mixins/navigation/NavigatesProgrammatically';
import MedimoLayout from '@/vue/components/general/layout/MedimoLayout';
import UsesMediaQueries from '@/vue/mixins/UsesMediaQueries';
import SchemaButton from '@/vue/components/status/SchemaButton';
import MedimoTooltip from '@/vue/components/general/MedimoTooltip';

export default {
  emits: ['toggle_show_all','triggered'],
  components: {
    MedimoTr,
    MedimoTd,
    MedimoLayout,
    SchemaButton,
    MedimoTooltip,
  },

  props: {
    'all_dosage_instruction_ids': {},
    'has_future_instructions': {},
    'medication_treatment': {},
    'medication_agreement': {},
    'dosage_instruction': {},
    'dosage_instruction_index': {},
    'show_all': {},
    'number_of_dosage_instructions': {},
    'read_only': {},
    'show_ingang_per': {default: true},
    'show_if_stopped': {default: false},
    'doseringColumnClasses': {default: 'col-sm-5 col-print-5'},
    'ingangPerColumnClasses': {default: 'col-sm-4 col-print-4'},
  },

  mixins: [
    IsPageNavItem,
    NavigatesProgrammatically, // Needed for the programmatic navigation on click
    UsesMediaQueries,
  ],

  data: function () {
    return {
      //
    };
  },

  computed: {
    patient() {
      return this.$store.getters['patients/find'](this.dosage_instruction.patient_id);
    },
    drug() {
      return this.$store.getters['drugs/find'](this.dosage_instruction.drug_id);
    },
    readOnly() {
      if (this.read_only && this.read_only === true) {
        return this.read_only;
      }
      return this.patient.readOnly;
    },
    medicatieColumnClasses() {
      return this.show_ingang_per ? 'col-sm-3' : 'col-sm-7';
    },
    show() {
      // Always show temprarily stopped rows
      // Always show when show_if_stopped
      if (this.dosage_instruction.temporary_stop || this.show_if_stopped) {
        return true;
      }


      if (this.dosage_instruction.active == 0) {
        return false;
      }

      // If stopped in past dont show
      if (this.getStopDateTime && Dates.setDate(this.getStopDateTime).isTodayOrFuture() === false) {
        return false;
      }

      // When all should be open, return all
      if (this.show_all) {
        return true;
      }

      // Always show if it's the first row
      if (this.isFirstDosageInstruction) {
        return true;
      }
      // When it's between the stop and end date
      else if (Dates.nowIsBetweenDates(this.dosage_instruction.start_moment_datetime, this.dosage_instruction.stop_datetime)) {
        return true;
      }

      return false;
    },
    noticeStart() {
      return Dates.setDate(this.dosage_instruction.display_start_moment).isTodayOrFuture();
    },
    stopType() {
      if (this.dosage_instruction.temporary_stop) {
        return 'STNO'; // stop tot nader order
      }
      return 'STOP';
    },
    stopTypeDescription() {
      if (this.dosage_instruction.temporary_stop) {
        return 'Stop tot nader order, tijdelijke stop';
      }
      return 'Definitieve stop';
    },
    parsedStartDate() {
      return Dates.setDate(this.dosage_instruction.display_start_moment).normalizedDateTime();
    },
    predecessor() {
      return this.$store.getters['dosage_instructions/predecessor'](this.dosage_instruction);
    },
    startMomentDateTime() {
      // 2022-06-02 Vervangen door this.dosage_instruction.display_start_moment
      // Gestopte regel, toon de startMomentDateTime van voor het stoppen
      if (this.predecessor &&
            this.dosage_instruction.mutation && this.dosage_instruction.mutation.type === 'S' &&
            moment(this.dosage_instruction.start_moment_datetime).isBefore(moment())
      ) {
        return this.predecessor.start_moment_datetime;
      }
      // Default, toon de startMomentDateTime
      return this.dosage_instruction.start_moment_datetime;
    },
    getStopDateTime() {
      if (this.dosage_instruction.mutation_datetime) {
        return this.dosage_instruction.mutation_datetime;
      } else {
        return this.dosage_instruction.stop_datetime;
      }
    },
    parsedStopDateTime() {
      return Dates.setDate(this.getStopDateTime).normalizedDateTime();
    },
    dosage_to_string() {
      return this.$store.getters['dosage_instructions/human_dosage_string'](this.dosage_instruction, this.patient);
    },
    isFirstDosageInstruction() {
      return this.all_dosage_instruction_ids[0] === this.dosage_instruction.id;
    },

    // Used by the mixin
    to() {
      return '/status/' + this.patient.id + '/medication-treatment/' + this.dosage_instruction.medication_treatment_id;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    navigate() {
      this.$emit('triggered');
      if (!this.readOnly) {
        this.navigateProgrammatically();
      }
    }
  },

  watch: {
    //
  }
};
</script>
