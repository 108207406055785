<template>
  <medimo-form class="data-viewer">
    <div class="data-tree">

      <div class="row my-2"  v-if="show && withSearch && (isJson || isXML)">
        <div class="col-sm-3">
          <medimo-input class="search" v-model="query" :placeholder="queryPlaceholder"></medimo-input>
        </div>
        <div class="col-sm-9 d-flex">
          <medimo-labeled-checkbox class="toggle-matches" v-model="onlyShowMatches" label="Matches-only" label_position="right"></medimo-labeled-checkbox>
          <medimo-labeled-checkbox class="toggle-match-content pl-5" v-model="showMatchContent" label="Toon match content" label_position="right"></medimo-labeled-checkbox>
          <medimo-labeled-checkbox class="toggle-fade pl-5" v-model="fadeMatchContent" label="Fade non-match" label_position="right"></medimo-labeled-checkbox>
        </div>
      </div>
      <div class="root" v-if="show">
        <json-tree v-if="isJson"
                   :query="query"
                   :onlyShowMatches="onlyShowMatches"
                   :showMatchContent="showMatchContent"
                   :fadeMatchContent="fadeMatchContent"
                   :auto-collapse-after="autoCollapseAfter"
                   :properties-to-show="propertiesToShow"
                   :raw-data="rawData" />

        <xml-tree v-else-if="isXML"
                  :query="query"
                  :onlyShowMatches="onlyShowMatches"
                  :showMatchContent="showMatchContent"
                  :fadeMatchContent="fadeMatchContent"
                  :auto-collapse-after="autoCollapseAfter"
                  :properties-to-show="propertiesToShow"
                  :raw-data="rawData" />

        <!-- Als hij met niks matched tonen we gewoon formatted text -->
        <pre v-else>{{ rawData }}</pre>
      </div>
      <div class="row my-2" v-else>
        <medimo-button @click="show = true">Toon data</medimo-button>
      </div>
    </div>
  </medimo-form>
</template>

<script>

import JsonTree from './Json/JsonTree';
import XmlTree from './Xml/XmlTree';
import MedimoTextarea from '@/vue/components/general/form/base/MedimoTextarea';
import MedimoForm from "@/vue/components/general/form/MedimoForm.vue";
import XmlTreeBranch from "@/vue/components/data-viewers/Xml/XmlTreeBranch.vue";
import MedimoLabeledCheckbox from "@/vue/components/general/form/base/MedimoLabeledCheckbox.vue";
import MedimoInput from "@/vue/components/general/form/base/MedimoInput.vue";
import MedimoLabeledDateTimePicker from "@/vue/components/general/form/base/MedimoLabeledDateTimePicker.vue";

export default {
  components: {MedimoLabeledDateTimePicker, MedimoInput, MedimoLabeledCheckbox, XmlTreeBranch, MedimoForm, MedimoTextarea, XmlTree, JsonTree},

  props: {
    rawData: {type: String, required: true},
    withSearch: {default: true},
    queryPlaceholder: {default: 'Zoek...'},
    propertiesToShow: {type: Array, default: []},
    autoCollapseAfter: {default: 3}, // Collapsed de regels als ze 3 niveaus + diep zijn. De root telt al voor 1
  },

  data: function () {
    return {
      show: false,
      query: '',
      onlyShowMatches: true,
      fadeMatchContent: true,
      showMatchContent: true,
    };
  },

  computed: {
    isJson() {
      if (!this.show) return false;
      if (typeof this.rawData !== 'string') return false;
      try {
        const result = JSON.parse(this.rawData);
        const type = Object.prototype.toString.call(result);
        return type === '[object Object]'
            || type === '[object Array]';
      } catch (err) {
        return false;
      }
    },
    isXML() {
      if (!this.show) return false;
      if (this.rawData === '') {
        return false;
      }
      try {
        $.parseXML(this.rawData); //is valid XML
        return true;
      } catch (error) {
        return false;
      }
    }
  },
  created() {
    // If it's a large file, do not render until the user wants it
    if (this.rawData.length < 100000) {
      this.show = true;
    }
  }
};
</script>
<style lang="scss">
@import "/variables";

.data-tree {
  .data-tree-branch {
    min-width: 250px;
    white-space: nowrap;
    line-height: 20px;
  }

  .root {
    overflow-x: auto;
    > .data-tree-branch {
      margin-bottom: 10px;
    }
  }

  .data-tree-tag-value {
    font-weight: 600;
  }

  .data-tree-tag-close {
    // Dit moet om een rare span HTML rendering te corrigeren
    //left: -5px;
    position: relative;
  }
  .data-tree-data-name {
    color: $medimo;
  }
  .data-tree-attr {
    font-style: italic;

    .data-tree-attr-name {
      color: $red;
    }
    .data-tree-attr-value {
      color: $medimo-75;
    }
  }
}

</style>
