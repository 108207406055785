<template>
  <component :is="type" :style="{ whiteSpace: whiteSpaceStyle }">
    {{ textFormatted }}
  </component>
</template>

<script lang="ts" setup>
import {computed} from "vue";

interface Props {
  text: string,
  isPreWrap?: boolean
  type?: string,
}

const props = withDefaults(defineProps<Props>(), {
  type: 'span',
  isPreWrap: true
});

// Make sure line breaks work, API escapes them and then it will not execute the linebreak
const textFormatted = computed<string>(() => props.text.replace(/\\n/g, "\n")
  .replaceAll('<br>', '\n')
    .replaceAll('<br/>', '\n')
  .replaceAll('<br />', '\n')
);

// Pre-wrap will preserve any spaces and tabs
// Pre-line will collapse any spaces and tabs
const whiteSpaceStyle = computed<string>(() => (props.isPreWrap ? "pre-wrap" : "pre-line"));
</script>
