<template>
  <medi-modal :loading="loading" title="BSN Opvragen" size="medium" @closed="$emit('closed')">
    <medimo-form>
      <medimo-form-row>
        <medimo-form-column column_classes="col-12">
          <medimo-notification v-if="bsn_values.notification" type="warning" :exclamation="true">
            <span>{{ bsn_values.notification }}</span>
          </medimo-notification>
          <MedimoFormattedText v-if="message" type="p" :text="message" :is-pre-wrap="false" />
          <p v-if="bsn_values.bsn">Het huidige BSN is: {{ bsn_values.bsn }}</p>
        </medimo-form-column>
      </medimo-form-row>
    </medimo-form>

    <template v-slot:action-buttons>
      <medimo-button @click="bsnCheck">
        <span v-if="patient.bsn">BSN Controleren</span>
        <span v-else>BSN Opvragen</span>
      </medimo-button>
      <medimo-button @click="show_wid_check_modal = true">
        WID Controle
      </medimo-button>
      <medimo-button class="mr-auto" type="outline-primary" @click="show_bsn_history_modal = true">
        Historie
      </medimo-button>
      <medimo-button @click="$emit('closed')">
        Sluiten
      </medimo-button>
    </template>

    <bsn-history-modal
        v-if="show_bsn_history_modal"
        :patient_id="patient.id"
        @closed="show_bsn_history_modal = false"
    ></bsn-history-modal>

    <wid-check-modal
        v-if="show_wid_check_modal"
        :patient="patient"
        @closed="show_wid_check_modal = false"
    ></wid-check-modal>
  </medi-modal>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import BsnHistoryModal from './BsnHistoryModal';
import WidCheckModal from './WidCheckModal';
import MedimoFormattedText from "@/vue/components/general/MedimoFormattedText.vue";

export default {
  components: {
    MedimoFormattedText,
    WidCheckModal,
    BsnHistoryModal
  },

  emits: ['closed'],

  mixins: [
    BaseComponentsMixin
  ],

  props: [
    'patient'
  ],

  data: function () {
    return {
      loading: false,
      show_bsn_history_modal: false,
      show_wid_check_modal: false,
      message: '',
    };
  },

  created() {
    this.fetchValues();
  },
  computed: {
    bsn_values() {
      const bsn_values = this.$store.state.bsn.values[this.patient.id];

      if (typeof bsn_values === 'undefined') {
        return [];
      }

      return bsn_values;
    },
  },
  methods: {
    fetchValues() {
      // only fetch when not already available
      if (this.bsn_values.length !== 0) {
        return;
      }

      this.loading = true;

      this.$store.dispatch('bsn/index', {
        patient_id: this.patient.id
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },
    bsnCheck() {
      this.loading = true;

      this.$store.dispatch('bsn/performBsnCheck', {
        patient_id: this.patient.id
      }).then(() => {
        this.loading = false;
        this.message = this.$store.state.bsn.checkBsnResult[this.patient.id];
      }).catch(() => {
        this.loading = false;
      });
    },
  }
};
</script>
