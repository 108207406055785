<template>
    <medi-modal
            :loading="loading"
            title="BSN historie"
            size="medium"
            :patient_id="patient_id"
            @closed="$emit('closed')"
    >
        <medimo-table
                :read_only="true"
                :enable_filter="false"
                :number_of_rows="bsn_history.length"
                :copyable="true"
        >
            <medimo-thead></medimo-thead>
            <medimo-tbody>
                <medimo-tr :disabled="true" :key="bsn_history.id" v-for="history in bsn_history">
                    <medimo-td col_width="12">
                      <MedimoFormattedText type="div" :text="history.log" />
                      Ingevoerd op: {{ parsedDateTime(history.time) }} door {{ history.user_name }}
                    </medimo-td>
                </medimo-tr>
            </medimo-tbody>
        </medimo-table>

    </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import moment from 'moment-mini';
import MedimoFormattedText from "@/vue/components/general/MedimoFormattedText.vue";

export default {
  components: {MedimoFormattedText},
  emits: ['closed'],
  props: [
    'patient_id'
  ],

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      loading: false,
      picked: '',
    };
  },

  created() {
    this.fetchHistory();
  },

  computed: {
    bsn_history() {
      const bsn_history = this.$store.state.bsn.history[this.patient_id];

      if (typeof bsn_history === 'undefined') {
        return [];
      }

      return bsn_history;
    },

  },

  methods: {
    fetchHistory() {

      // If don't already have some history, we show the loader
      if (this.bsn_history.length === 0) {
        this.loading = true;
      }

      this.$store.dispatch('bsn/fetchHistory', {
        patient_id: this.patient_id
      }).then(() => {
        this.loading = false;
      }).catch(() => {
        this.loading = false;
      });
    },

    parsedDateTime(time) {
      return moment(time).format('DD-MM-YYYY HH:mm:ss');
    }

  },

  watch: {
    //
  }

};
</script>
