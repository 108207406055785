<template>
  <MediModal
      :id="'find-zindex-intolerance-table'"
      :class="'find-zindex-intolerance-table'"
      :title="title"
      :loading="loading"
      size="large"
      :min_height="0"
      @closed="$emit('closed');"
  >
    <MedimoExternalDataTable
        :store_name="store_name"
        :refresh_table="refresh_table"
        :name="'patient-zindex-intolerances-' + patient.id"
        ref="dataTable"
        :privacy_filter="false"
    >
      <template v-slot:extra_buttons>
        <MedimoFormColumn>
          <MedimoButtonLink :outline="true" :icon="icon_geneesmiddel" :transparent="!btn_geneesmiddel_enabled" @click="setFilterGeneesmiddel" :padding_class="'py-0 pr-2 pl-2 text-small'" class="quick-filter-button">
            Geneesmiddel
          </MedimoButtonLink>
        </MedimoFormColumn>

        <MedimoFormColumn>
          <MedimoButtonLink :outline="true" :icon="icon_groep" :transparent="!btn_groep_enabled" @click="setFilterGroep" :padding_class="'py-0 pr-2 pl-2 text-small'" class="quick-filter-button">
            Groep
          </MedimoButtonLink>
        </MedimoFormColumn>

        <MedimoFormColumn>
          <MedimoButtonLink :outline="true" :icon="icon_stofnaam" :transparent="!btn_stofnaam_enabled" @click="setFilterStofnaam" :padding_class="'py-0 pr-2 pl-2 text-small'" class="quick-filter-button">
            Stofnaam
          </MedimoButtonLink>
        </MedimoFormColumn>
      </template>

      <MedimoThead>
        <MedimoTr>
          <MedimoTh column_classes="col col-sm-12" :store_name="store_name" sort_column_1="text">Naam</MedimoTh>
        </MedimoTr>
      </MedimoThead>
      <MedimoTbody>
        <template v-for="model in dataTable?.records">
          <FindZindexIntoleranceDataTableRow :intolerance="model" @selected="zindexIntoleranceSelected(model)" />
        </template>
      </MedimoTbody>
    </MedimoExternalDataTable>

    <template v-slot:action-buttons>
      <MedimoButton @click="close">
        Sluiten
      </MedimoButton>
    </template>
  </MediModal>
</template>

<script setup lang="ts">
import FindZindexIntoleranceDataTableRow from './FindZindexIntoleranceDataTableRow.vue';
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MedimoButton from "@/vue/components/general/MedimoButton.vue";
import MediModal from "@/vue/components/general/MediModal.vue";
import MedimoButtonLink from "@/vue/components/general/MedimoButtonLink.vue";
import MedimoFormColumn from "@/vue/components/general/form/base/MedimoFormColumn.vue";
import {computed, ref} from "vue";
import {Patient} from "@/domain/models/Patient";
import {onMounted} from "@vue/runtime-core";
import {store} from "@/vue/store";

const emit = defineEmits(['closed', 'zindexIntoleranceSelected']);

interface Props {
  patient: Patient
}

const props = defineProps<Props>();
const dataTable = ref();
const store_name = 'intolerances_zindex';
const title = ref<string>('');
const refresh_table = ref<number>(0);
const loading = ref<boolean>(false);
const btn_geneesmiddel_enabled = ref<boolean>(true);
const btn_groep_enabled = ref<boolean>(false);
const btn_stofnaam_enabled = ref<boolean>(false);

const icon_geneesmiddel = computed<string>(() => {
  return btn_geneesmiddel_enabled.value ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
});

const icon_groep = computed<string>(() => {
  return btn_groep_enabled.value ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
});

const icon_stofnaam = computed<string>(() => {
  return btn_stofnaam_enabled.value ? 'fa-solid fa-square-check' : 'fa-regular fa-square';
});

onMounted(() => {
  // When ready, we set the search endpoint
  setFilterGeneesmiddel();
});

function close() {
  emit('closed');
}
function setFilterGeneesmiddel() {
  store.commit(store_name + '/set_search_endpoint', '/api/v1/patients/' + props.patient.id + '/intolerances/zindex-search/geneesmiddel');
  store.commit(store_name + '/sort', {column_name: 'text', direction: 'ASC'});
  title.value = 'Zoek op geneesmiddel';
  refresh_table.value++;
  btn_geneesmiddel_enabled.value = true;
  btn_groep_enabled.value = false;
  btn_stofnaam_enabled.value = false;
}
function setFilterGroep() {
  store.commit(store_name + '/set_search_endpoint', '/api/v1/patients/' + props.patient.id + '/intolerances/zindex-search/groep');
  title.value = 'Zoek op groep';
  refresh_table.value++;
  btn_geneesmiddel_enabled.value = false;
  btn_groep_enabled.value = true;
  btn_stofnaam_enabled.value = false;
}
function setFilterStofnaam() {
  store.commit(store_name + '/set_search_endpoint', '/api/v1/patients/' + props.patient.id + '/intolerances/zindex-search/stofnaam');
  title.value = 'Zoek op stofnaam';
  refresh_table.value++;
  btn_geneesmiddel_enabled.value = false;
  btn_groep_enabled.value = false;
  btn_stofnaam_enabled.value = true;
}
function zindexIntoleranceSelected(model: {type: string, id: number}) {
  loading.value = true;
  store.dispatch('intolerances_zindex/fetchOptions', {
    patient_id: props.patient.id,
    type: model.type,
    id: model.id
  }).then(() => {
    loading.value = false;
    emit('zindexIntoleranceSelected', model);
  }).catch(() => {
    loading.value = false;
  });
}
</script>
