<template>
  <MediModal
      id="find-zindex-indication-table"
      class="find-zindex-indication-table"
      title="Zoek contra-indicatie om toe te voegen"
      size="large"
      :min_height="0"
      @closed="close"
  >
    <MedimoExternalDataTable
        :store_name="store_name"
        :name="'patient-zindex-indications-' + patient.id"
        ref="dataTable"
        :privacy_filter="false"
    >
      <MedimoThead>
        <MedimoTr>
          <MedimoTh column_classes="col col-sm-6" :store_name="store_name" sort_column_1="THNM50">Naam</MedimoTh>
          <MedimoTh column_classes="col col-sm-6" :store_name="store_name" sort_column_1="THAKD2">Medicatiebewaking in G-standaard</MedimoTh>
        </MedimoTr>
      </MedimoThead>
      <MedimoTbody>
        <template v-for="model in dataTable?.records">
          <FindZindexIndicationDataTableRow :indication="model" @selected="$emit('zindexIndicationSelected', model)" />
        </template>
      </MedimoTbody>
    </MedimoExternalDataTable>
  </MediModal>
</template>

<script setup lang="ts">
import FindZindexIndicationDataTableRow from './FindZindexIndicationDataTableRow.vue';
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MediModal from "@/vue/components/general/MediModal.vue";
import {ref} from "vue";
import {Patient} from "@/domain/models/Patient";
import {onMounted} from "@vue/runtime-core";
import {store} from "@/vue/store";

const emit = defineEmits(['zindexIndicationSelected','closed']);

const dataTable = ref();
const store_name = 'indications_zindex'

interface Props {
  patient: Patient
}

const props = defineProps<Props>();

onMounted(() => {
  // When ready, we set the search endpoint
  store.commit(store_name + '/set_search_endpoint', '/api/v1/patients/' + props.patient.id + '/contra-indications/zindex-options');
});

function close() {
  emit('closed');
}
</script>
