<template>
    <medimo-table :read_only="true" tabindex="1" :enable_filter="false" no_results_message="Geen medicatie gevonden."
                  :number_of_rows="medication_treatments.length"
                  :loading="show_table_loader"
                  :autofocus="false"
                  :can_be_focused="false"
                  id="status-overview-medication-table"
    >
        <medimo-thead>
            <medimo-tr class="no-gutters" :keynav="false">
                <medimo-th :column_classes="medicatieColumnClasses">
                    Medicatie
                </medimo-th>
                <medimo-th column_classes="col-sm-5">
                    Dosering
                </medimo-th>
                <medimo-th column_classes="col-sm-4" v-if="show_ingang_per">
                    Ingang per
                </medimo-th>
            </medimo-tr>
        </medimo-thead>
        <medimo-tbody :transition_group="true" transition_name="fade-fast">
            <medication-treatment-row v-for="medication_treatment in medication_treatments"
                                      :medication_treatment="medication_treatment"
                                      :key="medication_treatment.id"
                                      :read_only="true"
                                      :show_ingang_per="show_ingang_per"
            ></medication-treatment-row>
        </medimo-tbody>
    </medimo-table>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MedicationTreatmentRow from '../MedicationTreatmentRow';

export default {
  components: {
    MedicationTreatmentRow,
  },

  props: {
    'patient': {required: true},
    'loading': {default: false}, // Can override the local loading value
    'show_ingang_per': {default: true},
    'dateTime': {default: null},
  },

  mixins: [
    BaseComponentsMixin,
  ],

  data: function () {
    return {
      fetching_medication_treatments: false,
    };
  },

  computed: {
    medication_treatments() {
      return this.$store.getters['medication_treatments/sorted_db_data'].filter(medicationTreatment => {
        // Met deze krijgen we ook echt de "Geen xxx gevonden" regel te zien ipv lege regels _als_ de patient geen regels heeft
        return medicationTreatment.patient_id === this.patient.id;
      });
    },
    show_table_loader() {
      // When the external loading is true, we can disable this one, there's already a loader somewhere.
      if (this.loading) {
        return false;
      }
      // When the external is false, we use the local version
      return this.fetching_medication_treatments;
    },
    medicatieColumnClasses() {
      return this.show_ingang_per ? 'col-sm-3' : 'col-sm-7';
    },
  },

  async created() {
    // Only show the loader when there aren't any medication_treatments in the store.
    if (this.medication_treatments.length === 0) {
      this.fetching_medication_treatments = true;
    }
    // Triggerde 2 keer for whatever reason
    await this.$store.dispatch('medication_treatments/fetch', {
      patientId: this.patient.id,
      dateTime: this.dateTime,
    }).then(async (response) => {
      await this.$store.dispatch('initial_data/process_backend_medication_data', {
        data: response.data,
        only_update: true,
      });
    }).finally(() => {
      this.fetching_medication_treatments = false;
    });
  },
};
</script>
