<template>
    <patient-info-medi-modal
        id="contra-indication-data-table-modal"
        :patient_id="patient.id"
        :loading="loading"
        title="Contra-indicaties"
        size="large"
        :min_height="0"
        @closed="close"
    >
      <medimo-external-data-table
          :store_name="store_name"
          :number_of_rows="models.length"
          :refresh_table="refresh_table"
          :search_on_load="false"
          :pagination_enabled="false"
          :no_results_message="no_results_message"
          :name="'patient-indications-' + patient_id"
          privacy_filter_property="patient_id"
          :privacy_filter_value="patient_id"
      >
        <medimo-thead>
          <medimo-tr>
            <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="naam">Naam</medimo-th>
            <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="registerDate">Vaststellingsmoment</medimo-th>
            <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="info">Bijzonderheden</medimo-th>
            <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="stopdat">Stopt per</medimo-th>
          </medimo-tr>
        </medimo-thead>
        <medimo-tbody>
          <template v-for="model in models">
            <indications-data-table-row
                :indication="model"
                :patient="patient"
                @selected="openEditIndicationModal(model)"
            ></indications-data-table-row>
          </template>
        </medimo-tbody>
      </medimo-external-data-table>

      <template v-slot:action-buttons>
        <medimo-button class="mr-2" @click="openFindZindexIndicationModal">Nieuwe toevoegen</medimo-button>
        <medimo-button class="mr-2" @click="updateNoIndicationsReason" v-if="indications === null || indications.length === 0">{{ update_reason_button_label }}</medimo-button>
        <medimo-button class="mr-md-auto" type="outline-primary" @click="openHistoryModal">Historie</medimo-button>
        <medimo-button @click="close">Sluiten</medimo-button>
      </template>

      <medi-modal v-if="show_history_modal" title="Contra-indicaties historie" @closed="show_history_modal = false">
        <log-table type="contra-indications" :typeId="patient.id" :enable_filter="false" />
      </medi-modal>

      <add-indication-modal
          :patient="patient"
          v-if="show_add_indication_modal"
          :zindex_indication="selected_zindex_indication"
          @closed="closeAddIndicationModal"
      ></add-indication-modal>

      <edit-indication-modal
          v-if="show_edit_indication_modal"
          :indication="selected_indication"
          :loading="loading"
          :patient="patient"
          @closed="closeEditIndicationModal"
      ></edit-indication-modal>

      <find-zindex-indication-data-table-modal
          v-if="show_find_zindex_modal"
          :patient="patient"
          @zindexIndicationSelected="zindexIndicationSelected"
          @closed="show_find_zindex_modal = false"
      >
      </find-zindex-indication-data-table-modal>

    </patient-info-medi-modal>
</template>

<script>
import Utility from '@/vue/utility/utility';
import IndicationsDataTableRow from './IndicationsDataTableRow';
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import PatientInfoMediModal from '@/vue/components/modals/PatientInfoMediModal';
import AddIndicationModal from './AddIndicationModal';
import EditIndicationModal from './EditIndicationModal';
import FindZindexIndicationDataTableModal from './FindZindexIndicationDataTableModal';
import LogTable from '@/vue/components/log/tables/LogTable';

export default {
  components: {
    LogTable,
    FindZindexIndicationDataTableModal,
    IndicationsDataTableRow,
    PatientInfoMediModal,
    AddIndicationModal,
    EditIndicationModal
  },

  emits: ['closed'],

  props: {
    'patient': {required: true},
  },

  mixins: [BaseComponentsMixin],

  data: function () {
    return {
      store_name: 'indications',
      loading: false,
      show_history_modal: false,
      show_add_indication_modal: false,
      show_edit_indication_modal: false,
      show_find_zindex_modal: false,
      refresh_table: 0,
      selected_indication: false,
      selected_zindex_indication: false,
      show_indications_button: true,
    };
  },

  computed: {
    patient_id() {
      return this.patient.id;
    },
    models() {
      return this.$store.getters[this.store_name + '/search_results'].filter((model) => {
        return model.patient_id === this.patient_id;
      });
    },
    indications: {
      get() {
        return this.$store.getters['indications/active'];
      },
      set(value) {
        this.$store.commit('indications/set_data', value);
      }
    },
    no_results_message() {
      if (this.indications === null) {
        return 'Onbekend of er contra-indicaties zijn';
      } else {
        return 'Geen contra-indicaties aanwezig';
      }
    },
    update_reason_button_label() {
      if (this.indications === null) {
        return 'Geen contra-indicaties';
      } else {
        return 'Contra-indicaties onbekend';
      }
    },
  },

  created() {
    let data = Utility.deep_clone(this.$store.state.indications.data);
    if (data === null) data = [];
    this.$store.commit(this.store_name + '/set_search_results', data.reverse());
  },

  mounted() {
    // When ready, we set the search endpoint
    this.$store.commit(this.store_name + '/set_search_endpoint', '/api/v1/patients/' + this.patient.id + '/contra-indications');
  },

  methods: {
    close() {
      this.$emit('closed');
      this.show_history = false;
    },
    updateNoIndicationsReason() {
      this.$store.dispatch('indications/updateNoIndicationsReason', {
        patient_id: this.patient.id,
        data: {
          reason: this.indications === null ? 'known' : 'unknown',
        },
      }).then((result) => {
        this.indications = result.indications_updated_at === null ? null : [];
      });
    },
    openEditIndicationModal(indication) {
      this.selected_indication = indication;
      this.show_edit_indication_modal = true;
    },
    closeEditIndicationModal(refresh) {
      this.show_edit_indication_modal = false;
      if (refresh) {
        this.refresh_table++;
      }
    },
    openFindZindexIndicationModal() {
      this.show_find_zindex_modal = true;
    },
    zindexIndicationSelected(model) {
      this.selected_zindex_indication = model;
      this.show_find_zindex_modal = false;     // close find modal
      this.show_add_indication_modal = true;  // open add modal
    },
    closeAddIndicationModal(refresh) {
      this.show_add_indication_modal = false;
      if (refresh) {
        this.refresh_table++;
      }
    },
    openHistoryModal() {
      this.show_history_modal = true;
    },
  },

  watch: {
    models() {
      this.show_indications_button = this.models.length <= 0;
    }
  }
};
</script>
