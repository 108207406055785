<template>
    <div :class="'alert-container p-0 pr-sm-3 ' + (hasAlerts ? 'has-alerts' : '')" style="z-index: 10000">
        <div style="position:fixed;" class="w-100 positioner sticky-element">
            <div class="flex-row mr-0 mr-sm-3" v-if="alerts.length">
                <div :class="'canvas p-2 col-12 col-sm-6 col-md-' + cols + ' ml-auto mr-auto'" style="z-index:10000;">
                    <medimo-alert
                            :id="alert.id"
                            v-for="(alert, id) in alerts"
                            :exclamation="alert.type === 'danger'"
                            :key="alert.id + alert.timestamp"
                            :type="alert.type"
                            :hide_after="alert.visible_for"
                            :to="alert.href"
                            :margin_top="id !== 0"
                            :margin_bottom="id !== (alerts.length - 1)"
                        >
                            {{ alert.title }}
                            <template v-slot:body v-if="alert.message">
                                <MedimoFormattedText :text="alert.message" :is-pre-wrap="false" />
                            </template>
                        </medimo-alert>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MedimoAlert from './MedimoAlert';
import MedimoFormattedText from "@/vue/components/general/MedimoFormattedText.vue";

export default {
  components: {MedimoFormattedText, MedimoAlert},

  props: {
    cols: {default: 3},
  },

  mixins: [],

  data: function () {
    return {
      //
    };
  },

  computed: {
    alerts() {
      return this.$store.state.notifications.alerts;
    },
    hasAlerts() {
      return this.alerts.length > 0;
    }
  },

  created() {
    //
  },

  mounted() {
    //
  },

  methods: {
    //
  },

  watch: {
    hasAlerts: {
      immediate: true,
      handler(value) {
        // Als er alerts open staan, willen we voorkomen dat het sluiten van die alerts
        // ook een modal sluit. Vandaar dat we deze watcher gebruiken om dat indirect
        // aan te sturen.
        this.$store.commit('settings/modals/set_open_alert', value);
      }
    }
  }
};
</script>
