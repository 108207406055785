<template>
  <MediModal
      title="Import historie"
      size="large"
      :patient_id="patient_id"
      @closed="$emit('closed')"
  >
    <MedimoExternalDataTable
        name="edi-log-data-table"
        ref="dataTable"
        :store_name="store_name"
        :privacy_filter="false"
        quick_filter_buttons_class="col-md-5"
    >
      <MedimoThead>
        <MedimoTr>
          <MedimoTh column_name_1="datum/tijd" :store_name="store_name" sort_column_1="created_at" column_classes="col-sm-2"></MedimoTh>
          <MedimoTh column_name_1="Apotheek" :store_name="store_name" sort_column_1="pharmacy.naam"></MedimoTh>
          <MedimoTh column_name_1="Melding" :store_name="store_name" sort_column_1="message"></MedimoTh>
          <MedimoTh column_name_1="Succesvol" :store_name="store_name" sort_column_1="success" column_classes="col-sm-2"></MedimoTh>
        </MedimoTr>
      </MedimoThead>
      <MedimoTbody>
        <template v-for="log in dataTable?.records" :key="log.id">
          <MedimoTr :link="(log.link_to_data ?? '')" target="_blank">
            <MedimoTd column_classes="col-sm-2"> {{ log.created_at }}</MedimoTd>
            <MedimoTd> {{ log.pharmacy }}</MedimoTd>
            <MedimoTd> {{ log.message }}</MedimoTd>
            <MedimoTd column_classes="col-sm-2"> {{ log.success }}</MedimoTd>
          </MedimoTr>
        </template>
      </MedimoTbody>
    </MedimoExternalDataTable>
  </MediModal>
</template>

<script setup lang="ts">
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MedimoTd from "@/vue/components/general/table/MedimoTd.vue";
import MediModal from "@/vue/components/general/MediModal.vue";
import {store} from "@/vue/store";
import {onBeforeMount, ref} from "vue";

interface Props {
  patient_id?: number
}

const dataTable = ref();
const props = defineProps<Props>();

const store_name = 'log_import';

onBeforeMount(() => {
  store.commit(store_name + '/set_search_endpoint', '/api/v1/logs/import/' + props.patient_id);
});
</script>
