<template>
  <medimo-external-data-table
      :name="'patient-readings-' + patient_id"
      :store_name="store_name"
      :number_of_rows="models.length"
      :refresh_table="refresh_table"
      :search_on_load="initial_load"
      :pagination_enabled="false"
      :results_per_page="'all'"

      privacy_filter_property="patient_id"
      :privacy_filter_value="patient_id"
  >
    <medimo-thead>
      <medimo-tr>
        <medimo-th column_classes="col col-sm-2" :store_name="store_name" sort_column_1="timestamp">Datum</medimo-th>
        <medimo-th column_classes="col col-sm-4" :store_name="store_name" sort_column_1="bepaling">Meting</medimo-th>
        <medimo-th column_classes="col col-sm-3" :store_name="store_name" sort_column_1="uitslag">Waarde</medimo-th>
        <medimo-th column_classes="col col-sm-3" :store_name="store_name">Referentie</medimo-th>
      </medimo-tr>
    </medimo-thead>
    <medimo-tbody>
      <template v-for="model in models">
        <reading-data-table-flat-row
            :inactive="inactive"
            :reading="model"
            :patient="patient"
            @selected="$emit('openEditReadingModal', model)"
        ></reading-data-table-flat-row>
      </template>
    </medimo-tbody>
  </medimo-external-data-table>
</template>

<script>
import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import ReadingDataTableFlatRow from './ReadingDataTableFlatRow';

export default {
  emits: ['openEditReadingModal'],

  components: {
    ReadingDataTableFlatRow,
  },

  props: {
    'refresh_table': {required: true},
    'patient': {required: true},
    'inactive': {default: false},
  },

  mixins: [
    BaseComponentsMixin
  ],

  data: function () {
    return {
      store_name: 'readings',
    };
  },

  computed: {
    patient_id() {
      return this.patient.id;
    },
    models() {
      return this.$store.getters[this.store_name + '/search_results'].filter((model) => {
        return model.patient_id === this.patient_id;
      });
    },
    initial_load() {
      return this.models.length === 0;
    }
  },
};

</script>
