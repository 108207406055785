<template>
  <medi-modal
      @closed="ignoreForNow"
      :hide_overlay="hide_overlay"
      :loading="loading"
      size="small"
  >
    <template v-slot:modal-header>
      <div class="modal-title col-auto">
        {{ action.name }}
      </div>
    </template>

    <div v-if="availableForVerification">
      <p>De huidige medicatie van {{ language.patient }} is afkomstig uit de thuissituatie en dient geverifieerd te worden.</p>
      <p>De medicatie dient binnen afzienbare tijd (liefst &lt; 24 uur) na opname geverifieerd te worden.</p>
      <p>Alle medicatie wordt op dat moment gestopt en er worden medicatievoorstellen gedaan voor herstart.</p>
      <p v-if="!canVerifyMedication">Vraag een voorschrijver om de medicatie binnenkort te verifiëren.</p>
    </div>
    <div v-else>
      {{ action.description }}
      <p>Momenteel wordt de medicatielijst nog ingelezen vanuit het apotheeksysteem.
        Deze medicatie zal door blijven lopen op moment van opname, maar dient wel geverifieerd te worden.</p>
      <p>Verificatie kan pas nádat de import vanuit apotheeksysteem is uitgezet in Medimo.</p>
    </div>

    <template v-slot:action-buttons v-if="canVerifyMedication">
      <medimo-button type="success" class="w-25" @click="submit('accept')">
        Akkoord
      </medimo-button>
      <medimo-button type="danger" v-if="canDecline" class="w-25" @click="submit('decline')">
        Weiger
      </medimo-button>
      <div class="d-inline mr-auto"></div>
      <medimo-button type="button" class="btn btn-primary ml-1" @click="ignoreForNow">
        Nu even niet
      </medimo-button>
    </template>
    <template v-slot:action-buttons v-else>
      <medimo-button type="button" class="btn btn-primary ml-1" @click="ignoreForNow">
        Sluiten
      </medimo-button>
    </template>

  </medi-modal>
</template>

<script>

import BaseComponentsMixin from '@/vue/mixins/BaseComponentsMixin';
import MediModal from '@/vue/components/general/MediModal';

export default {
  components: {
    MediModal,
  },

  emits: ['closed','processed'],

  props: [
    'action',
    'hide_overlay',
    'patient',
    'refresh_patient_medication_data',
  ],

  mixins: [
    BaseComponentsMixin,
  ],

  data: function () {
    return {
      agreed: false,
      note: '',
      loading: false,

      checked: false,
    };
  },

  computed: {
    language() {
      return this.$store.getters['current_user/language'];
    },
    availableForVerification() {
      // patient mag geen import meer zijn
      return !this.patient.currentlyImport;
    },
    canVerifyMedication() {
      // actie moet available zijn en gebruiker moet geautoriseerd zijn
      return this.availableForVerification && this.patient.authority.canPrescribePatient;
    },
    canDecline() {
      // Als er met toedienlijsten wordt gewerkt en de client heeft medicatieregels met alleen import-gegevens, dan mogen we niet weigeren
      return !(this.hasImportInstructions && (this.patient.location.tdl === 'Y' || this.patient.location.moduleTDR === 'Y'));
    },
    hasImportInstructions() {
      return this.$store.getters['dosage_instructions/sorted_data'].filter(dosing_instruction => {
        return dosing_instruction.wcia !== '' && dosing_instruction.quantity === '';
      }).length;
    },
  },

  methods: {
    close() {
      this.$emit('closed');
    },
    ignoreForNow() {
      this.close();
    },
    submit(value) {
      this.loading = true;
      return this.$store.dispatch('patients/update_action_from_patient', {
        rejectErrors: true,
        action_id: this.action.id,
        patient_id: this.action.patientId,
        handler: 'verification',
        refresh_patient_medication_data: this.refresh_patient_medication_data,
        data: {
          value: value,
        }
      }).then(() => {
        // Afronden en melding tonen
        this.$store.dispatch('notifications/addSuccessAlert', {message: 'Gegevens opgeslagen'});

        this.$emit('processed');
        this.close();
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
