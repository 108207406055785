<template>
  <MediModal
      title="Trombosedienst historie"
      size="large"
      :patient-id="patientId"
      @closed="emit('closed')"
  >
    <MedimoExternalDataTable
        name="thrombosis-log-data-table"
        ref="dataTable"
        :store_name="storeName"
        :privacy_filter="false"
        quick_filter_buttons_class="col-md-5"
    >
      <MedimoThead>
        <MedimoTr>
          <MedimoTh column_name_1="Datum/tijd" :store_name="storeName" sort_column_1="created_at" column_classes="col-sm-2"></MedimoTh>
          <MedimoTh column_name_1="Trombosedienst" :store_name="storeName" sort_column_1="importer"></MedimoTh>
          <MedimoTh column_name_1="Melding" :store_name="storeName" sort_column_1="message"></MedimoTh>
        </MedimoTr>
      </MedimoThead>
      <MedimoTbody>
        <template v-for="log in dataTable?.records as ThrombosisLog[]" :key="log.id">
          <MedimoTr @click="loadModal(log)">
            <MedimoTd column_classes="col-sm-2"> {{ log.created_at }}</MedimoTd>
            <MedimoTd> {{ log.importer }}</MedimoTd>
            <MedimoTd> {{ log.message }}</MedimoTd>
          </MedimoTr>
        </template>
      </MedimoTbody>
    </MedimoExternalDataTable>
  </MediModal>

  <MediModal title="Trombosedienstmelding" @closed="showModal = false" v-if="showModal" :loading="loading">
    <div v-html="htmlContent"></div>
  </MediModal>
</template>

<script setup lang="ts">
import MediModal from "@/vue/components/general/MediModal.vue";
import MedimoExternalDataTable from "@/vue/components/general/table/MedimoExternalDataTable.vue";
import MedimoThead from "@/vue/components/general/table/MedimoThead.vue";
import MedimoTbody from "@/vue/components/general/table/MedimoTbody.vue";
import MedimoTr from "@/vue/components/general/table/MedimoTr.vue";
import MedimoTh from "@/vue/components/general/table/MedimoTh.vue";
import MedimoTd from "@/vue/components/general/table/MedimoTd.vue";
import {ThrombosisLog} from "@/domain/models/ThrombosisLog";
import {onMounted, ref} from "vue";
import {store} from "@/vue/store";

const emit = defineEmits(['closed']);

interface Props {
  patientId: number,
}

const props = defineProps<Props>();

const storeName = ref('log_thrombosis');
const loading = ref(false);
const showModal = ref(false);
const htmlContent = ref('');
const dataTable = ref();

onMounted(() => {
  store.commit(storeName.value + '/set_search_endpoint', '/api/v1/patients/' + props.patientId + '/thrombosis/history');
});

function loadModal(log: ThrombosisLog): void {
  loading.value = true;
  showModal.value = true;

  store.dispatch('api/getEndpoint', {
    'endpoint': '/api/v1/patients/' + props.patientId + '/thrombosis/popup/' + log.id
  }).then(response => {
    htmlContent.value = response.data;
    loading.value = false;
  }).catch(() => {
    loading.value = false;
  });
}

</script>
