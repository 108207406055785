<template>
  <div :class="'medimo-tabs tabs row ' + (whiteTabBackground ? 'bg-white' : '') + ' ' + marginClasses + ' ' + ' ' + paddingClasses + ' ' + (page_tabs ? 'page-tabs' : '') + ' ' + (no_border_radius_top ? 'no-border-radius-top' : '') + ' ' + (no_border_radius_bottom ? 'no-border-radius-bottom' : '')"
  >
    <component :is="'h1'" v-if="title.length" class="ml-4 mb-3">
      <component class="d-inline" :is="'h1'">{{ title }}</component>
      <span v-if="subtitle.length" class="h1 bright-blue">|</span>
      <small v-if="subtitle.length">{{ subtitle }}</small>
    </component>

    <div class="mx-4 w-100" v-if="!!$slots.custom">
      <slot name="custom"></slot>
    </div>
    <ul class="nav nav-tabs w-100 pl-4">
      <li :class="'nav-item clickable'" v-for="(tab_name, index) in tab_names" v-show="tab_name.length">
        <a class="nav-link" @click="clickedTab(index)" v-bind:class="{'active': isSelected(index)}">
          <slot :name="slotName('header', index)">
            <component v-if="typeof tab_name !== 'string'" :is="tab_name" />
            <span v-else>{{tab_name }}</span>
          </slot>
        </a>
      </li>
    </ul>
    <div class="col-12 tab-content" v-bind:class="{'no-border-radius-top': no_border_radius_top, 'no-border-radius-bottom': no_border_radius_bottom}"
         v-for="(ignored, index) in tab_names" v-show="isSelected(index)" v-bind:style="{minHeight: min_height}">
      <page-block :minimum_height="min_height" :transparent="true" class="mb-0 pb-0 px-2 pt-2">
        <slot :name="slotName('content', index)"></slot>
      </page-block>
    </div>
  </div>
</template>

<script>
import PageBlock from "@/vue/components/general/layout/PageBlock.vue";

export default {
  components: {PageBlock},
  emits: ['clickedTab'],

  props: {
    title: {default: ''},
    subtitle: {default: ''},
    margin_classes_override: {default: ''},
    padding_classes_override: {default: ''},
    whiteTabBackground: {default: true},
    // The tab names in Array or Object format
    'tab_names': {default: {}},

    // Set the tab that should be opened by default
    'tab_open_by_default': {default: 1},
    // Set to true to switch tabs in the parent by modifying the tab_open_by_default value
    'changeTabsInParent': {default: false},

    'min_height': {default: '100px'},
    // Set this to true if the tabs are page-wide and you want the styling for it
    'page_tabs': {default: true, type: Boolean},

    no_border_radius_top: {default: false}, // Set to true to enable the top corner radius
    no_border_radius_bottom: {default: true}, // Set to true to enable the bottom corner radius
  },
  data: function () {
    return {
      selected_slot: 0,
    };
  },

  computed: {
    firstTab() {
      return Object.keys(this.tab_names)[0];
    },
    marginClasses() {
      if (this.margin_classes_override.length) {
        return this.margin_classes_override;
      }
      return 'mt-1 mx-0 mb-2';
    },
    paddingClasses() {
      if (this.padding_classes_override.length) {
        return this.padding_classes_override;
      }
      if (this.title.length) {
        return 'pt-3';
      }
      return 'pt-4';
    }
  },

  methods: {
    clickedTab(key) {
      if (! this.changeTabsInParent) {
        this.selected_slot = key;
      }
      this.$emit('clickedTab', Array.isArray(this.tab_names) ? key + 1 : key);
    },
    isSelected(key) {
      let isSelected = false;
      if (key === undefined) {
        return isSelected;
      }
      // If it is an array, the key is the index
      if (Array.isArray(this.tab_names)) {
        return this.selected_slot === key;
      }

      // If we have an object, and the tab name is defined, check if they are the same
      if (typeof this.tab_names[this.selected_slot] !== 'undefined') {
        isSelected = this.selected_slot.toString() === key.toString();
        return isSelected;
      }

      // If the tab is not found, but selected_slot is a number, try to match the keys in their order
      if (typeof this.selected_slot === 'number') {
        isSelected = Object.keys(this.tab_names)[this.selected_slot - 1] === key;
        return isSelected;
      }

      return false;
    },
    slotName(pre, key) {
      if (typeof key === 'number') return pre + '-' + (key + 1);

      return pre + '-' + key;
    }
  },

  watch: {
    // Set the selected_slot on mount, and if it changes afterwards
    tab_open_by_default: {
      immediate: true,
      handler(value) {
        this.selected_slot = Array.isArray(this.tab_names) ? value - 1 : value;
      }
    }
  }
};
</script>

<style scoped lang="scss">
  @import "/variables";
  @import '/bootstrap/functions';
  @import '/bootstrap/variables';

.page-tabs {
  ul.nav-tabs {
    li.nav-item {
      margin-right: 0.2em;

      .nav-link {
        background: $medimo-5;
        border-color: $medimo-10;
        border-bottom-color: $gray-300;

        &.active {
          background: white;
          border-color: $medimo-75;
          border-bottom-color: white;
          cursor: default;
        }
      }
    }

    .badge {
      height: 17px;
      line-height: 9px;
    }
  }

  .tab-content {
    border: none;

    // Niet bij pageBlock een border om de tabs content - links en rechts
    border-bottom-width: 0;
    border-right-width: 0;
    border-left-width: 0;
  }
}
</style>
